export const es = {
  //Navbar Starts here
  "Log Out": "Cerrar sesión",
  "All account data of @account_name will be deleted. Do you really want to log out?":
    "Todos los datos de la cuenta de @{{account_name}} serán eliminados. ¿Realmente deseas cerrar sesión?",
  Cancel: "Cancelar",
  Support: "Soporte",
  "Terms of Service": "Términos de servicio",
  "Privacy Policy": "Política de privacidad",
  "Manage Subscriptions": "Gestionar suscripciones",
  Logout: "Cerrar sesión",
  // Subscription starts here
  "Manage Subscription": "Administrar suscripción",
  Active: "Activo",
  "Do you want to cancel? Reach our friendly support team here":
    "¿Quieres cancelar?<br/>Contacta a nuestro amable equipo de soporte <tag>aquí</tag>",
  // Footer starts here
  "Terms and Conditions": "Términos y condiciones",
  "Privacy Policy": "Política de privacidad",
  "Copyright ® 2025. All Rights Reserved.":
    "Copyright ® 2025. Todos los derechos reservados.",
  // payment screen starts here
  "90% OFF": "90% DE DESCUENTO",
  "FLASH SALE": "VENTA RELÁMPAGO",
  "Real, organic social": "Crecimiento social real y orgánico",
  media: "medios",
  success: "éxito",
  fans: "seguidores",
  growth: "crecimiento",
  engagement: "interacción",
  "Forget automated bots and generic tools. AskMe ensures authentic growth by connecting you with your audience through AI-driven precision.":
    "Olvida los bots automatizados y las herramientas genéricas. AskMe garantiza un crecimiento auténtico conectándote con tu audiencia mediante precisión impulsada por IA.",
  "Start Growing Today": "Comienza a crecer hoy",
  "Limited-Time Offer": "Oferta por tiempo limitado",
  "FLASH SALE": "VENTA RELÁMPAGO",
  "FEATURED IN": "DESTACADO EN",
  "Featured In": "Destacado en",
  "The proven path to organic growth":
    "El camino probado<tag/>hacia el crecimiento orgánico",
  "Trusted by 17,000+ Influencers and Brands":
    "Confiado por más de 17,000<tag/>influencers y marcas",
  "Experience safe and sustainable media growth with AskMe's authentic approach.":
    "Experimenta un crecimiento de medios seguro y sostenible con el enfoque auténtico de AskMe.",
  "Get Started Now": "Comienza ahora",
  "What Makes AskMe the Best Choice?":
    "¿Qué hace de AskMe<tag/>la mejor opción?",
  "Live Strategy Assistance": "Asistencia de estrategia en vivo",
  "We take the time to understand your brand, audience, and objectives, crafting a growth plan tailored to your unique needs. This hands-on approach ensures every step of your journey is optimized for success.":
    "Nos tomamos el tiempo para entender tu marca, audiencia y objetivos, creando un plan de crecimiento adaptado a tus necesidades únicas. Este enfoque práctico asegura que cada paso de tu camino esté optimizado para el éxito.",
  "AI Audience Targeting": "Segmentación de audiencia con IA",
  "Using advanced AI algorithms, AskMe pinpoints the exact audience that will resonate with your content. Our strategic engagement fosters authentic connections, driving consistent and meaningful growth.":
    "Usando algoritmos avanzados de IA, AskMe identifica la audiencia exacta que resonará con tu contenido. Nuestro compromiso estratégico fomenta conexiones auténticas, impulsando un crecimiento constante y significativo.",
  "Steady and Reliable Growth Results":
    "Resultados de crecimiento constantes y confiables",
  "By collaborating with AskMe, your Instagram evolves into a powerful platform - whether you’re seeking brand visibility, influencer reach, or sales generation. Real growth, every step of the way.":
    "Al colaborar con AskMe, tu Instagram se convierte en una plataforma poderosa, ya sea que busques visibilidad de marca, alcance de influencers o generación de ventas. Crecimiento real, en cada paso del camino.",
  "Take the First Step": "Da el primer paso",
  "Struggling with Instagram Growth? Join AskMe, We've Got You Covered!":
    "¿Luchando con el crecimiento en Instagram? Únete a AskMe, ¡te tenemos cubierto!",
  "Imagine waking up every day excited about the work you do. With AskMe, you can take control of your career and carve out a path that’s as flexible as it is fulfilling. Whether you’re building a personal brand, growing a business, or pursuing influencer opportunities, the possibilities are endless. AskMe is designed to help you create an Instagram presence that attracts the audience you’ve always dreamed of. From loyal followers to collaborations with top brands, we’ll guide you every step of the way to achieve your goals. Here’s what you can expect when you partner with AskMe:":
    "Imagina despertar cada día emocionado por el trabajo que haces. Con AskMe, puedes tomar el control de tu carrera y trazar un camino tan flexible como satisfactorio. Ya sea que estés construyendo una marca personal, haciendo crecer un negocio o persiguiendo oportunidades de influencer, las posibilidades son infinitas. AskMe está diseñado para ayudarte a crear una presencia en Instagram que atraiga a la audiencia que siempre has soñado. Desde seguidores leales hasta colaboraciones con las mejores marcas, te guiaremos en cada paso para alcanzar tus metas. Esto es lo que puedes esperar al asociarte con AskMe:",
  "Engaged followers": "Seguidores comprometidos",
  "Brand Partnerships": "Asociaciones de marca",
  "Full flexibility": "Flexibilidad total",
  "Perfect Life Balance": "Equilibrio de vida perfecto",
  "And so much more...": "Y mucho más...",
  "How it Works": "Cómo funciona",
  "The 3-Step Strategy Process": "El proceso de estrategia de 3 pasos",
  "1. You Define Your Audience": "1. Tú defines tu audiencia",
  "Share details about the followers you want: age, location, interests, and more.":
    "Comparte detalles sobre los seguidores que deseas: edad, ubicación, intereses y más.",
  "2. We Engage the Right People":
    "2. Nosotros involucramos a las personas correctas",
  "AskMe promotes your content to your ideal Instagram audience through organic strategies.":
    "AskMe promueve tu contenido a tu audiencia ideal de Instagram mediante estrategias orgánicas.",
  "3. You get quality followers": "3. Obtienes seguidores de calidad",
  "You build a true community of real, engaged Instagram followers who genuinely love your content.":
    "Construyes una comunidad verdadera de seguidores reales y comprometidos en Instagram que genuinamente aman tu contenido.",
  "Your Instagram Account Is Safe: No login credentials are required.":
    "Tu cuenta de Instagram está segura: no se requieren credenciales de inicio de sesión.",
  "At AskMe, we ensure that our influencer plans fully comply with Instagram's TOS. Unlike many other services that require you to share your account login to provide social growth, working with us guarantees that your Instagram account remains completely secure.":
    "En AskMe, nos aseguramos de que nuestros planes de influencers cumplan plenamente con los TOS de Instagram. A diferencia de muchos otros servicios que requieren que compartas tus credenciales de inicio de sesión para proporcionar crecimiento social, trabajar con nosotros garantiza que tu cuenta de Instagram permanezca completamente segura.",
  "Get 1-2K Followers per Month!": "¡Obtén 1-2K seguidores por mes!",
  "7-Day Trial": "Prueba de 7 días",
  "Total Today": "Total hoy",
  "$9.99": "$9.99",
  "$1.00": "$1.00",
  "Get My Plan": "Obtén mi plan",
  "Instagram Username": "Nombre de usuario de Instagram",
  Email: "Correo electrónico",
  "Card Number": "Número de tarjeta",
  "Expires on": "Expira el",
  CVC: "CVC",
  "Process Credit Card": "Procesar tarjeta de crédito",
  OR: "O",
  "FAQs: We’re Here to Help": "Preguntas frecuentes: Estamos aquí para ayudar",
  "Still unsure? Reach out to us via email or live chat, and we’ll answer all your questions!":
    "¿Aún no estás seguro? Contáctanos por correo electrónico o chat en vivo, ¡y responderemos todas tus preguntas!",
  "Is my Instagram account safe?": "¿Está segura mi cuenta de Instagram?",
  "Absolutely. Unlike other services, we don’t use bots, automation, or require your login credentials. Your account’s security is our top priority. AskMe’s methods are 100% organic.":
    "Absolutamente. A diferencia de otros servicios, no usamos bots, automatización ni requerimos tus credenciales de inicio de sesión. La seguridad de tu cuenta es nuestra máxima prioridad. Los métodos de AskMe son 100% orgánicos.",
  "How does AskMe stand out": "¿Cómo se destaca AskMe?",
  "We offer unmatched affordability and personalized service, backed by round-the-clock live chat support, making us the go-to choice for organic growth.":
    "Ofrecemos una asequibilidad inigualable y un servicio personalizado, respaldado por soporte de chat en vivo las 24 horas, lo que nos convierte en la opción preferida para el crecimiento orgánico.",
  "Do you use bots or fake accounts?": "¿Usan bots o cuentas falsas?",
  "Never. We prioritize organic strategies like influencer collaborations and targeted outreach.":
    "Nunca. Priorizamos estrategias orgánicas como colaboraciones con influencers y alcance dirigido.",
  "What happens if I cancel my subscription?":
    "¿Qué sucede si cancelo mi suscripción?",
  "Your followers remain yours, even after cancellation.":
    "Tus seguidores siguen siendo tuyos, incluso después de la cancelación.",
  "When will I see results?": "¿Cuándo veré resultados?",
  "Results are often visible within 24 hours, with maximum impact achieved within 7 days.":
    "Los resultados suelen ser visibles en 24 horas, con el máximo impacto alcanzado en 7 días.",
  "Are the followers real?": "¿Son reales los seguidores?",
  "Yes. All followers gained through AskMe are genuine and organically attracted to your content.":
    "Sí. Todos los seguidores obtenidos a través de AskMe son genuinos y atraídos orgánicamente por tu contenido.",
  "Who benefits from AskMe?": "¿Quién se beneficia de AskMe?",
  "Whether you’re a business, influencer, or someone growing a personal Instagram account, AskMe delivers effective results for all.":
    "Ya seas un negocio, un influencer o alguien que hace crecer una cuenta personal de Instagram, AskMe ofrece resultados efectivos para todos.",
  "What types of accounts are eligible?":
    "¿Qué tipos de cuentas son elegibles?",
  "AskMe works with personal, business, and influencer accounts of all sizes.":
    "AskMe trabaja con cuentas personales, comerciales y de influencers de todos los tamaños.",
  "What’s required on my end?": "¿Qué se requiere de mi parte?",
  "All you need is an Instagram account. You can even manage multiple profiles with AskMe.":
    "Todo lo que necesitas es una cuenta de Instagram. Incluso puedes gestionar múltiples perfiles con AskMe.",
  "How many followers can I gain?": "¿Cuántos seguidores puedo obtener?",
  "Our clients typically grow by at least 1,000 followers per month through authentic engagement techniques.":
    "Nuestros clientes suelen crecer al menos 1,000 seguidores por mes mediante técnicas de compromiso auténtico.",
  "1,000 - 2,500 Followers /mo": "1,000 - 2,500 Seguidores /mes",
  "Average results across our<br/>accounts, results may be higher or<br/>lower based on your niche<br/>and content.": "Resultados promedio en nuestras<br/>cuentas, los resultados pueden ser más altos o<br/>más bajos según tu nicho<br/>y contenido.",
  "Follower Growth Immediately": "Crecimiento de seguidores inmediato",
  "Get set-up within hours and<br/>start growing today.": "Configúrate en pocas horas y<br/>comienza a crecer hoy.",
  "Guaranteed Results Or It's Free": "Resultados garantizados o es gratis",
  "Guaranteed growth within 7 days or<br/>we'll give you a refund.": "Crecimiento garantizado en 7 días o<br/>te devolveremos tu dinero.",
  "New York Team & Support": "Equipo y soporte en Nueva York",
  "Our entire team is based<br/>locally in New York.": "Todo nuestro equipo está<br/>ubicado en Nueva York.",
  "Engaged, Organic Followers": "Seguidores comprometidos y orgánicos",
  "All followers are acquired from<br/>organic promotion, for building<br/>your Instagram community.": "Todos los seguidores se adquieren mediante<br/>promoción orgánica, para construir<br/>tu comunidad en Instagram.",
  "Advanced Al Targeting": "Segmentación avanzada con IA",
  "We use AI to target<br/>your audience.": "Usamos IA para segmentar<br/>tu audiencia.",
  "Dedicated 24/7 Support": "Soporte dedicado 24/7",
  "Our dedicated support team is<br/>available 24/7 to answer your queries.": "Nuestro equipo de soporte dedicado<br/>está disponible 24/7 para responder tus consultas.",
  "$1.33 Per Day": "$1.33 Por Día",
  "$1.66 Per Day": "$1.66 Por Día",
  "Instagram Growth": "Crecimiento en Instagram",
  "$99": "$99",
  "$49.99": "$49.99",
  "$39.99": "$39.99",
  "The fastest way to grow with advanced targeting, perfect for rapidly expanding influencers and brands.":
    "La forma más rápida de crecer con segmentación avanzada, perfecta para influencers y marcas en expansión."

  ,
  "/month":"/mes",
  // otp screen starts here
  "ENTER OTP": "INGRESA OTP",
  "A one-time password has been sent to your Email:":
    "Se ha enviado una contraseña de un solo uso a tu correo electrónico: {{email}}",
  "Resend OTP": "Reenviar OTP",
  // activity screen starts here
  Followers: "Seguidores",
  Following: "Siguiendo",
  "7-Day Activity": "Actividad de 7 días",
  "30-Day Activity": "Actividad de 30 días",
  "Account Settings": "Configuración de la cuenta",
  "Ensure your account is set to PUBLIC":
    "Asegúrate de que tu cuenta esté configurada como <tag>PÚBLICA</tag>",
  Important: "Importante",
  "Please make sure to TURN OFF the 'Flag for Review' option. You can find it in the Follower and Invite Friends settings in the Instagram app. This will help ensure everything runs smoothly.":
    "Asegúrate de <tag>DESACTIVAR la opción 'Marcar para revisión'</tag>. Puedes encontrarla en la configuración de Seguidores e Invitar amigos en la aplicación de Instagram. Esto ayudará a garantizar que todo funcione sin problemas.",
  Warning: "Advertencia",
  "Your Instagram username is incorrect, and we cannot start your order. Please update it so we can proceed.":
    "Tu nombre de usuario de Instagram es incorrecto y no podemos comenzar tu pedido. Por favor, actualízalo para que podamos proceder.",
  Update: "Actualizar",
  "Instagram Username": "Nombre de usuario de Instagram",
  "Invalid instagram username": "Nombre de usuario de Instagram inválido",
  "Restore Your Access with One Click!": "¡Recupera tu acceso con un solo clic!",
  "Your subscription has expired, but you can get everything back instantly!": "Tu suscripción ha expirado, pero puedes recuperar todo al instante.",
  "Reactivate now and unlock all premium features, including detailed account statistics, advanced growth analytics, and daily performance insights. Stay informed, and make smarter decisions with full access to your data. Don't miss out - get back on track today!": "Reactiva ahora y desbloquea todas las funciones premium, incluyendo estadísticas detalladas de la cuenta, análisis avanzados de crecimiento y conocimientos diarios sobre el rendimiento. Mantente informado y toma decisiones más inteligentes con acceso completo a tus datos. No te lo pierdas: ¡vuelve al camino hoy mismo!",
  "Resubscribe": "Volver a suscribirse",
  //login screen starts here
  "ASKME ACCOUNT": "CUENTA DE ASKME",
  "Enter your registered email address to get full data analysis":
    "Ingresa tu dirección de correo electrónico registrada para obtener un análisis completo de datos",
  "Get My Analytics": "Obtener mis análisis",
  "Forgotten your account name?": "¿Olvidaste tu nombre de cuenta?",
  //forgot screen starts here
  "Enter your registered email to get your username":
    "Ingresa tu correo electrónico registrado para obtener tu nombre de usuario",
  "Existing user login": "Inicio de sesión de usuario existente",
  Submit: "Entregar",
  //error texts
  "Enter Card Number": "Ingresa el número de tarjeta",
  "Enter Expiry Month": "Ingresa el mes de vencimiento",
  "Enter Expiry Year": "Ingresa el año de vencimiento",
  "Enter CVC": "Ingresa el CVC",
  "Enter Username": "Ingresa el nombre de usuario",
  "Enter Email": "Ingresa el correo electrónico",
  "Invalid Email": "Correo electrónico inválido",
  "Invalid Card Number": "Número de tarjeta inválido",
  "Invalid Month": "Mes inválido",
  "Invalid Year": "Año inválido",
  "Invalid CVC": "CVC inválido",
  "Email is required": "Se requiere correo electrónico",
  "Username is incorrect": "El nombre de usuario es incorrecto",
};
