export const legal_en_Translation = {
  terms_and_conditions: {
    title: "Terms and Conditions",
    intro:
      "This End User License Agreement is a valid agreement between You and the Company. Please, read this EULA carefully. The rules of the EULA are binding upon You. If You do not agree to be bound by the rules of the EULA, please, do not use the WebApp. If You continue to use the WebApp, this will mean that You have read and understood the EULA and agree to be bound by it.",
    definitions: {
      title: "1. DEFINITIONS AND TERMS",
      description:
        "In order to understand the rules of the EULA clearly you shall familiarize yourself with the meaning of terms used in the EULA. In this EULA the following terms, when capitalized, shall have the following meanings:",
      definitions_list: {
        account:
          "“Account” – means a personal account of a user of the Platform services, which is provided by the Platform Administrator under the Platform Terms of Use or similar document. The Account is accessed with a Platform ID (account name) and a password.",
        company:
          "“Company”, “We” – mean RBCOM LTD, a company incorporated in the Singapore at the following address: 1 Raffles Place, #40-02, One Raffles Place Tower 1, Singapore 048616.",
        content:
          "“Content” – means all kinds of content, including, among other, data, texts, images, videos, sounds, links, software and other pieces of information in any form, which are made available to You through the WebApp.",
        device:
          "“Device” – means a smartphone, a tablet or another similar mobile device that You either own or otherwise legally control exclusively for Your personal non-commercial use.",
        eula: "“EULA” – means this End User License Agreement, which is a binding agreement between You and the Company. The EULA may incorporate other documents, such as Privacy Policy that regulate relations between You and Us with regard to using the WebApp. If a document represents a part of the EULA, it has a clear indication thereof.",
        intellectual_property_rights:
          "“Intellectual Property Rights” – means, collectively, rights under any patent, trademark, copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral or similar rights.",
        subscription:
          "“Subscription” – means a period, during which You use the WebApp as a whole or certain additional Content for a fee.",
        user_generated_content:
          "“User-generated Content” – means any form of Content, including, among other, data, texts, images, videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g. Instagram, Facebook).",
        you: "“You” – means an individual using the WebApp.",
      },
    },
    changes_of_eula: {
      title: "2. CHANGES OF THE EULA",
      list: {
        changes_intro:
          "We may introduce changes to this EULA from time to time. You shall regularly check the latest version of the EULA at the link, available on the web-page.",
        changes_effective:
          "If You do not agree with the terms of the updated EULA, You are not allowed to use the WebApp starting from the day, on which the updated EULA takes effect. In this case You shall not use the WebApp and cancel renewal of your Subscriptions.",
      },
    },
    device: {
      title: "3. DEVICE",
      list: {
        use_non_owned_device:
          "If You intend use the WebApp on any Device not owned by You, You must have the owner’s permission to do so. You will be responsible for complying with this EULA whether or not You own the Device.",
        access_to_non_owned_device:
          "If You do not own the Device, You are allowed to use the WebApp only through Your Account.",
        responsibility:
          "If You use the Device together with other people, please, make sure that nobody except You can use the WebApp from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually takes such actions. You are fully responsible for all actions taken in Your Account.",
        removal:
          "If You decide to sell or otherwise dispose of the Device, You shall delete the WebApp from the Device.",
      },
    },
    capacity_and_age: {
      title: "4. LEGAL CAPACITY AND AGE",
      list: {
        over_16:
          "The WebApp is designed for users over 16. If You use the WebApp, You confirm that You are at least 16 years old and have full legal capacity to enter into the Agreement.",
        between_16_18:
          "If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to check and agree to this EULA on Your behalf. If You use the WebApp, You confirm that Your parent, guardian or other representative of legal age has agreed to the terms of this EULA and permits You to use the WebApp.",
      },
    },
    intellectual_property: {
      title: "5. INTELLECTUAL PROPERTY",
      list: {
        license_grant_and_webapp_use_terms:
          "We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access, download and run the Webapp only on Your Device, provided that You agree to comply with all the terms and conditions of this EULA. Please, take into consideration that the WebApp is licensed, not sold, to You.",
        intellectual_property_rights_and_reservation_of_rights:
          "The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to the WebApp. The Company retains all rights, title and interest in and to the WebApp and reserves all rights not expressly granted to You in this EULA.",
        restrictions_on_transferring_rights_to_webapp:
          "You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other way transfer or grant any rights to the WebApp to any third party.",
        restrictions_on_network_distribution_and_access:
          "You may not distribute or make the WebApp available over any network where it could be downloaded or used by multiple users at the same time, except when it is expressly authorized by Us.",
        prohibitions_on_interfering_with_webapp_functionality:
          "You may not use any automatic or manual device or process to interfere with the proper working of the WebApp. However, You may use any kind of device or process to delete the WebApp from Your Device.",
        prohibitions_on_decompiling_and_reverse_engineering:
          "You may not decompile, disassemble, reverse engineer or create derivative works of the WebApp or any parts thereof. All modifications or enhancements to the WebApp remain the sole property of Company.",
        prohibitions_on_unlawful_use_and_malicious_activity:
          "You may not use the WebApp in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this EULA or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the WebApp.",
      },
    },
    subscription_and_trial_period: {
      title: "6. SUBSCRIPTION AND TRIAL PERIOD",
      list: {
        subscription_basis_and_fee_variation:
          "This WebApp as a whole or its certain part (additional Content) is provided on a paid subscription basis. The fee may vary depending on the period of the Subscription duration.",
        automatic_renewal_and_account_charge:
          "The Subscription will renew automatically for the same period at the end of the Subscription at the cost of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to the end of the current Subscription.",
        cancellation_of_subscription_renewal:
          "You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this case the WebApp as a whole or its additional Content will be available to You until the end of the Subscription. In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your Account. After the end of the Subscription the WebApp as a whole or its additional Content will be available only if a new Subscription is purchased.",
        manual_cancellation_required:
          "Your subscription will not stop automatically. You must cancel automatic renewal of Your subscription in order not to be charged with the cost of the new Subscription.",
        free_trial_period_and_auto_conversion:
          "The WebApp provides for a free 7-day trial period. You may use the WebApp or its additional Content free of charge during this period. Please, take into consideration that at the end of the trial period it will automatically transform into a paid Subscription for the shortest period available and Your Account will be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription settings of Your Account at least 24 hours prior to the end of the free trial period.",
      },
    },
    fees_and_purchase_terms: {
      title: "7. FEES AND PURCHASE TERMS",
      list: {
        subscription_costs_availability:
          "The costs of all Subscriptions are available on the web-page.",
        payment_processing_by_platform_administrator:
          "When You purchase a Subscription, the Platform Administrator charges Your Account and collects money on Our behalf. The payment is processed by a payment processing system implemented by the Platform Administrator.",
        no_access_to_payment_information:
          "Please, kindly notice that We neither process Your payment nor have any access to Your payment information, such as, among other, Your bank card data, Your name or any other related information.",
        questions_on_payment_processing:
          "Should You have any question with regard to payment processing, please, submit Your issue to user support service of the Platform Administrator.",
        account_charge_timing:
          "Your Account is charged with the cost of the new Subscription within 24 hours prior to the end of the current Subscription. If You cancel automatic renewal within the last 24 hours of the Subscription, such cancellation will take effect at the end of the new Subscription.",
        no_refunds_and_immediate_content_access:
          "Please, kindly notice that We do not make refunds. The digital content is made available to You immediately after the Subscription fee is charged to Your Account.",
      },
    },
    user_generated_content: {
      title: "8. USER-GENERATED CONTENT",
      list: {
        exposure_to_objectionable_content:
          "As far as You may access posts of other Instagram users, You may be exposed to potentially objectionable User-generated Content. Please, take into account that the Company neither controls nor pre-checks any User-generated Content posted on any social network.",
        modifications_and_censorship:
          "Any kind of modifications or censorship by the Company with regard to the User-generated Content may be considered violation of human rights or copyright and may potentially mislead You.",
        handling_objectionable_content:
          "If You have been exposed to any kind of objectionable content, You may use functionality of the respective social network to track and delete objectionable User-generated Content.",
        company_disclaimer:
          "Under no circumstances shall the Company be responsible for any User-generated Content.",
      },
    },
    disclaimer: {
      title: "9. DISCLAIMER",
      list: {
        general_disclaimer_of_warranties:
          "To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents, partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy, adequacy or completeness of any of the information contained in Our WebApp or created by the services.",
        content_provided_as_is:
          "The Content in Our WebApp is provided to You on an “as is” basis, without any warranties as to merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any anticipated result from the use of the WebApp.",
        no_warranty_on_specifics: {
          title: "We make no warranty that:",
          a: "the WebApp will meet Your requirements;",
          b: "the WebApp will be error-free, secure, or uninterrupted;",
          c: "any WebApp functionality errors will be corrected;",
          d: "the quality of the WebApp will meet Your expectations.",
        },
        limitation_of_liability:
          "We shall have no responsibility or liability for any errors or omissions. We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by You or by any other person from the use of the WebApp, even if You have been advised of the possibility of such damages. No advice or information, whether oral or written, obtained by You from Us or Our WebApp shall create any warranty not expressly stated in the EULA. Under no circumstances shall Our liability or of any of Our affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by You for access to the WebApp. Furthermore, We shall have no responsibility or liability for delays or failures due to circumstances beyond Our control.",
      },
    },
    transfer_of_rights: {
      title: "10. TRANSFER OF RIGHTS",
      list: {
        transfer_by_company:
          "We may transfer Our rights and obligations under this EULA to any other organization or individual without Your consent. We will always tell You in writing if this happens and We will ensure that the transfer will not affect Your rights under the EULA.",
        transfer_by_user:
          "You may only transfer Your rights or Your obligations under this EULA to another person if We agree in writing.",
      },
    },
    termination_of_rights: {
      title: "11. TERMINATION OF RIGHTS",
      list: {
        termination_for_breach:
          "We may end Your rights to use the WebApp at any time by contacting You if You have broken the rules of this EULA in a serious way. If what You have done can be put right We will give You a reasonable opportunity to do so.",
        actions_upon_termination: {
          title: "If We end Your rights to use the WebApp:",
          a: "You must stop all activities authorized by this EULA, including Your use of the WebApp.",
          b: "You must delete or remove the WebApp from all Devices and immediately destroy all copies of the WebApp which You have and confirm to Us that You have done this.",
        },
      },
    },
    miscellaneous: {
      title: "12. MISCELLANEOUS",
      list: {
        separation_of_paragraphs:
          "Each of the paragraphs of this EULA operates separately. If any court or relevant authority decides that any of them is unlawful, the remaining paragraphs will remain in full force and effect.",
        delay_in_enforcement:
          "Even if We delay enforcing this EULA, We can still enforce it later. If We do not insist immediately that You do anything You are required to do under this EULA terms, or if We delay taking steps against You in respect of Your breaking the EULA, that will not mean that You do not have to do those things and it will not prevent Us taking steps against You at a later date.",
        governing_law:
          "This EULA shall be governed and construed in accordance with the laws of Dublin, Ireland, without regard to its conflict of law provisions.",
        dispute_resolution:
          "If any dispute, controversy or difference arises between You and the Company, it shall be settled amicably through negotiations. In case such dispute, controversy or difference cannot be settled amicably through negotiations within a thirty 30-day period it shall be settled by the Ireland Arbitration Association in accordance with its International Expedited Procedures.",
        number_of_arbitrators: "The number of arbitrators shall be one.",
        place_of_arbitrators:
          "The place of arbitration shall be Dublin, Ireland.",
        language_of_arbitrators:
          "The arbitration shall be held, and the award rendered, in English.",
        expense:
          "Each party (i.e. You and the Company) shall bear its own expenses,but the parties shall share equally the expenses of the arbitration tribunal.",
        support:
          " Support. If You want to learn more about the WebApp or have any problems using it, please, contact Us at <tag>support@ask-me.app</tag>",
      },
    },
  },
  privacy_policy: {
    title: "Privacy and Policy",
    commitment_title: "Our Commitment to Your Privacy",
    commitment_text:
      "We respect and protect the privacy of our WebApp users. This Privacy Policy explains how we collect and use your information and is part of our Terms of Use when you use our WebApp. By using our WebApp and the service provided via the WebApp (“Service”), you consent to our Privacy Policy. If you don’t agree, please don’t use our WebApp.",
    info_collection_title: "What information do we collect?",
    info_collection_text:
      "If you make purchases or buy a subscription, our third-party billing service providers will collect information such as your full name, credit card number, and billing address. If you email us, we may use your email address to reply to your email. Information Sent by Your Mobile Device. We collect certain information that your mobile device sends when you use our WebApp, like a device identifier, user settings and the operating system of your device, as well as information about your use of our Services. Location Information. When you use our WebApp, we may collect and store information about your location by converting your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect location information, you may disable that feature on your mobile device.",
    info_storage_title: "Where do we store your information?",
    info_storage_text:
      "Your information may be stored in our own servers or in servers owned by third-party cloud storage providers. Third-party storage providers may not use your information except as provided in this Privacy Policy.",
    info_usage_title: "How do we use your information?",
    info_usage_text: "Information we collect from you might be used:",
    info_storage_duration_title: "How long do we store your information?",
    info_storage_duration_text:
      "We intend to store some of your information indefinitely. What about links to other websites? We may provide links to or compatibility with other websites. However, we’re not responsible for the privacy practices employed by those websites or the information or content they contain.",
    info_protection_title: "How do we protect your information?",
    info_protection_text:
      "We use appropriate physical, electronic, and other procedures to safeguard and secure the information we collect. However, please be aware that the Internet is an inherently unsafe environment, and that hackers are constantly working to defeat security measures. Thus, we cannot guarantee that your information will not be accessed, disclosed, altered or destroyed, and you accept this risk.",
    user_protection_title: "How can you protect your information?",
    user_protection_text:
      "We urge you to take steps to keep your personal information safe by not sharing it with others or posting it online.",
    disclosure_title: "Do we disclose any information to outside parties?",
    disclosure_text:
      "We do not collect, sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information (PII) for commercial or marketing purposes, except as stated herein. Personal information (and non- personal information) collected through the Site may be shared with companies and organizations that perform services on our behalf (for example, companies that provide data management or other support services to us such as data storage and Web hosting services). We may share your personal information (and non-personal information) with third parties to serve you relevant advertising and market our products, but we won’t sell your personal information. Information Disclosed in Connection with Business Transactions. If we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third-party acquirer in connection with the transaction. Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.",
    not_for_children_title: "Not Intended for Children",
    not_for_children_text:
      "Our WebApp and Service are not intended for children under the age of 16. We do not knowingly or specifically collect information from or about children under the age of 16.",
    policy_changes_title: "Changes to our Privacy Policy",
    policy_changes_text:
      "If we decide to change our Privacy Policy, we will post those changes on this page. We may also, but are not required to, send you an email notice. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.",
    contact_title: "Contact Us",
    contact_text:
      "If you have questions about our Privacy Policy, please contact us at <tag>support@ask-me.app</tag>",
  },
};
