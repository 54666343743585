import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/styles/pages/otp.css";
import Logo from "../../../assets/svgs/Logo.svg";
import Button from "../../../components/common/Button";
import Loader from "../../../components/common/Loader";
import { loginUserState } from "../../../redux/slices/auth";
import {
  loginUser,
  updateInstagramDetails,
  verifyOTP,
} from "../../../services/api/api";
import { showToast } from "../../../utils/toast";

const Otp = () => {
  const email = localStorage?.getItem("email");
  const params = useLocation();
  const isNewUser =
    localStorage.getItem("new-user") &&
    JSON.parse(localStorage.getItem("new-user"))?.email === email;

  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingResend, setLoadingResend] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!email) {
  //     navigate(routeNames.landing);
  //   }
  // }, [email]);

  const handleVerification = async () => {
    try {
      setLoading(true);
      if (otp.length !== 4) {
        showToast("Invalid OTP", "error");
        return;
      }
      const response = await verifyOTP({ email, otp });
      if (response.status === 200) {
        if (params.state?.isNavigatedFromPayment || isNewUser) {
          await updateInstagramDetails({ token: response.data?.token }).catch(
            (err) => {
              console.log(err);
            }
          );
          if (isNewUser) {
            localStorage.removeItem("new-user");
          }
        }

        dispatch(loginUserState(response.data));
      } else {
        showToast(response.data?.message, "error");
      }
    } catch (err) {
      console.log(err);
      showToast(err?.request?.response || "Something went wrong", "error");
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    setLoadingResend(true);
    try {
      const response = await loginUser({ email });
      if (response.status === 200) {
        showToast(response.data?.message, "success");
      }
    } catch (err) {
      console.log(err);
      showToast(err?.request?.response || "Something went wrong", "error");
    } finally {
      setLoadingResend(false);
    }
  };

  return (
    <div className="grad-lavender-white-pink-v min-vh-100 d-flex flex-column justify-content-around common-padding-y common-padding-x">
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          <img src={Logo} alt="ask-me logo" />
        </div>
        <div className="otp-header">
          <p className="text-center text-uppercase grad-text-blue-pink fs-24 open-sans-800 m-0">
            {t("ENTER OTP")}
          </p>

          <p className="text-center text-black fs-14 fw-bold m-0 w-80">
            <Trans
              values={{ email: email }}
              i18nKey={"A one-time password has been sent to your Email:"}
              components={{ br: <br /> }}
            />
          </p>
        </div>
        <OTPInput
          value={otp}
          onChange={setOtp}
          containerStyle={"otp-container d-flex justify-content-center"}
          numInputs={4}
          inputStyle={"otp-input"}
          renderInput={(props) => <input {...props} inputMode="numeric" />}
        />
        {loading ? (
          <div className="w-100 d-flex justify-content-center py-3">
            <Loader />
          </div>
        ) : (
          <Button onClick={handleVerification} label={"Submit"} />
        )}
        <div className="d-flex justify-content-center">
          {loadingResend ? (
            <div
              className="w-100 d-flex justify-content-center"
              style={{ paddingTop: 25, paddingBottom: 25 }}
            >
              <Loader />
            </div>
          ) : (
            <div className="common-padding-y">
              <button
                onClick={resendOTP}
                className="resent-button fs-14 fw-bold text-decoration-underline bg-transparent text-center"
              >
                {t("Resend OTP")}
              </button>
            </div>
          )}
        </div>
      </div>
      <p className="fs-14 fw-regular text-black text-center">
        {t("Copyright ® 2025. All Rights Reserved.")}
      </p>
    </div>
  );
};

export default Otp;
