import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/styles/pages/login.css";
import { LogoIcon } from "../../../assets/svgs/common";
import Button from "../../../components/common/Button";
import Loader from "../../../components/common/Loader";
import { routeNames } from "../../../router/routes/routeNames";
import { loginUser } from "../../../services/api/api";
import { showToast } from "../../../utils/toast";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({
    touched: false,
    message: "",
  });

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const error = !emailRegex.test(e.target.value) ? t("Invalid Email") : "";
    setError((pre) => ({ ...pre, touched: true, message: error }));
  };

  const handleLogin = async () => {
    if (error.message) {
      return;
    } else if (!error.touched) {
      setError((pre) => ({
        ...pre,
        touched: true,
        message: t("Email is required"),
      }));
      return;
    } else {
      setLoading(true);
      await loginUser({ email: email })
        .then((response) => {
          if (response.status === 200) {
            showToast(response.data?.message);
            localStorage.setItem("email", email);
            navigate(routeNames.otp);
          }
          setLoading(false);
        })
        .catch((error) => {
          showToast(error?.request?.response, "error");
          setLoading(false);
        });
    }
  };

  return (
    <div className="grad-lavender-white-pink-v min-vh-100 d-flex flex-column justify-content-around common-padding-y common-padding-x">
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          <LogoIcon />
        </div>
        <div className="login-header">
          <p className="text-center text-uppercase grad-text-blue-pink fs-24 open-sans-800 m-0">
            {t("ASKME ACCOUNT")}
          </p>

          <p className="text-center text-black fs-14 fw-bold m-0 ">
            <Trans
              i18nKey={
                "Enter your registered email address to get full data analysis"
              }
              components={{ br: <br /> }}
            />
          </p>
        </div>
        <div className="login-input-wrapper">
          <input
            onChange={handleEmail}
            value={email}
            placeholder="Email"
            type="email"
            className="login-input"
          />
          <span className="login-error-text fs-10">{error?.message}</span>
        </div>
        {loading ? (
          <div className="w-100 d-flex justify-content-center py-3">
            <Loader />
          </div>
        ) : (
          <Button label={t("Get My Analytics")} onClick={handleLogin} />
        )}
        <div className="d-flex justify-content-center common-padding-y">
          <Link
            to={routeNames.forgot}
            className="resend-button fs-14 fw-bold text-center"
          >
            {t("Forgotten your account name?")}
          </Link>
        </div>
      </div>
      <p className="fs-14 fw-regular text-black text-center">
        {t("Copyright ® 2025. All Rights Reserved.")}
      </p>
    </div>
  );
};

export default Login;
