export const routeNames = {
  landing: "/",
  payment: "/",
  paymentP1: "/p1",
  paymentP2: "/p2",
  otp: "/otp",
  home: "/",
  terms: "/terms-of-service",
  privacy: "/privacy-policy",
  subscription: "/manage-subscription",
  login: "/login",
  forgot: "/forgot",
};
