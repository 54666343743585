import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { support_email } from "../../../assets/content/data";
import { terms_data } from "../../../assets/content/legal";

const {
  listOne,
  listTwo,
  listThree,
  listFour,
  listFive,
  listSix,
  listSeven,
  listEight,
  listNine,
  listTen,
  listTwelve,
} = terms_data;

const Terms = () => {
  return (
    <div className="py-3">
      <div className="text-center d-flex flex-column pb-4 min-vh-100">
        <div className="d-flex justify-content-center position-relative border border-black border-top-0 border-start-0 border-end-0 pb-3">
          <div>
            <Link
              to={-1}
              style={{ left: "15px" }}
              className="position-absolute"
            >
              <svg
                style={{ borderRadius: "50%", border: "1px solid #000" }}
                width="26px"
                height="26px"
                viewBox="0 0 1024 1024"
                fill="#000000"
                className="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z" />
              </svg>
            </Link>
            <h4 className="m-0 p-0">{t("terms_and_conditions.title")}</h4>
          </div>
        </div>
        <div
          style={{ textAlign: "left" }}
          className="d-flex flex-column px-4 mt-4"
        >
          <p>{t("terms_and_conditions.intro")}</p>
          <h3 className="mt-2">
            {t("terms_and_conditions.definitions.title")}
          </h3>
          <p>{t("terms_and_conditions.definitions.description")}</p>
          {listOne.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.changes_of_eula.title")}
          </h3>
          {listTwo.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">{t("terms_and_conditions.device.title")}</h3>
          {listThree.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.capacity_and_age.title")}
          </h3>
          {listFour.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.intellectual_property.title")}
          </h3>
          {listFive.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.subscription_and_trial_period.title")}
          </h3>
          {listSix.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.fees_and_purchase_terms.title")}
          </h3>
          {listSeven.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.user_generated_content.title")}
          </h3>
          {listEight.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">{t("terms_and_conditions.disclaimer.title")}</h3>
          {listNine.map((d) => (
            <p key={d.id}>
              {d?.id}
              {typeof d?.id === "number" ? "." : ")"} {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.transfer_of_rights.title")}
          </h3>
          {listTen.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2">
            {t("terms_and_conditions.termination_of_rights.title")}
          </h3>
          <p>
            1.{" "}
            {t(
              "terms_and_conditions.termination_of_rights.list.termination_for_breach"
            )}
          </p>
          <p>
            2.{" "}
            {t(
              "terms_and_conditions.termination_of_rights.list.actions_upon_termination.title"
            )}
          </p>
          <p>
            a){" "}
            {t(
              "terms_and_conditions.termination_of_rights.list.actions_upon_termination.a"
            )}
          </p>
          <p>
            b){" "}
            {t(
              "terms_and_conditions.termination_of_rights.list.actions_upon_termination.b"
            )}
          </p>
          <h3 className="mt-2">
            {t("terms_and_conditions.miscellaneous.title")}
          </h3>
          {listTwelve.map((d) => (
            <p key={d.id}>
              {d?.id}. {d?.data}
            </p>
          ))}
          <p>
            {t("terms_and_conditions.miscellaneous.list.number_of_arbitrators")}
          </p>
          <p>
            {t("terms_and_conditions.miscellaneous.list.place_of_arbitrators")}
          </p>
          <p>
            {t(
              "terms_and_conditions.miscellaneous.list.language_of_arbitrators"
            )}
          </p>
          <p>{t("terms_and_conditions.miscellaneous.list.expense")}</p>
          <p>
            5.{" "}
            <Trans
              i18nKey={"terms_and_conditions.miscellaneous.list.support"}
              components={{ tag: <Link to={`mailto:${support_email}`} /> }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
