export const generateRandomCode = () => {
  let randomNumber = Math.floor(Math.random() * 10000);

  let paddedNumber = randomNumber.toString().padStart(4, "0");

  return paddedNumber;
};

export const addS3Url = (url) => {
  return process.env.REACT_APP_S3_URL + url;
};

export const isValidObject = (obj) => {
  return (
    obj &&
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    Object.keys(obj).length > 0
  );
};

export const getLast7Days = () => {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(today.getDate() - i);

    const formattedDate = date.toISOString().split("T")[0];
    dates.push(formattedDate);
  }

  return dates;
};

export const getLast30Days = () => {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 30; i++) {
    const date = new Date();
    date.setDate(today.getDate() - i);

    const formattedDate = date.toISOString().split("T")[0];
    dates.push(formattedDate);
  }

  return dates;
};
