export const legal_es_Translation = {
  terms_and_conditions: {
    title: "Términos y Condiciones",
    intro:
      "Este Acuerdo de Licencia de Usuario Final es un acuerdo válido entre Usted y la Compañía. Por favor, lea este EULA detenidamente. Las reglas del EULA son vinculantes para Usted. Si no está de acuerdo con las reglas del EULA, por favor, no utilice la WebApp. Si continúa utilizando la WebApp, esto significará que ha leído y comprendido el EULA y acepta estar vinculado por él.",
    definitions: {
      title: "1. DEFINICIONES Y TÉRMINOS",
      description:
        "Para entender claramente las reglas del EULA, deberá familiarizarse con el significado de los términos utilizados en el EULA. En este EULA, los siguientes términos, cuando están en mayúsculas, tendrán los siguientes significados:",
      definitions_list: {
        account:
          "“Cuenta” – significa una cuenta personal de un usuario de los servicios de la Plataforma, que es proporcionada por el Administrador de la Plataforma según los Términos de Uso de la Plataforma o documento similar. La cuenta se accede con un ID de Plataforma (nombre de cuenta) y una contraseña.",
        company:
          "“Compañía”, “Nosotros” – significa RBCOM LTD, una compañía incorporada en Singapur en la siguiente dirección: 1 Raffles Place, #40-02, One Raffles Place Tower 1, Singapur 048616.",
        content:
          "“Contenido” – significa todo tipo de contenido, incluyendo, entre otros, datos, textos, imágenes, videos, sonidos, enlaces, software y otros elementos de información en cualquier forma, que están disponibles para Usted a través de la WebApp.",
        device:
          "“Dispositivo” – significa un teléfono inteligente, una tableta u otro dispositivo móvil similar que Usted posea o controle legalmente exclusivamente para su uso personal no comercial.",
        eula: "“EULA” – significa este Acuerdo de Licencia de Usuario Final, que es un acuerdo vinculante entre Usted y la Compañía. El EULA puede incorporar otros documentos, como la Política de Privacidad, que regulan la relación entre Usted y Nosotros con respecto al uso de la WebApp. Si un documento forma parte del EULA, tiene una clara indicación de ello.",
        intellectual_property_rights:
          "“Derechos de Propiedad Intelectual” – significa, en conjunto, los derechos bajo cualquier patente, marca registrada, derecho de autor y leyes de secretos comerciales, y cualquier otro derecho de propiedad intelectual o propietario reconocido en cualquier país o jurisdicción en todo el mundo, incluidos, sin limitación, los derechos morales o similares.",
        subscription:
          "“Suscripción” – significa un período durante el cual Usted utiliza la WebApp en su totalidad o cierto contenido adicional por una tarifa.",
        user_generated_content:
          "“Contenido Generado por el Usuario” – significa cualquier forma de Contenido, incluyendo, entre otros, datos, textos, imágenes, videos, sonidos, enlaces, que han sido publicados por usuarios en plataformas en línea, por ejemplo, redes sociales (como Instagram, Facebook).",
        you: "“Usted” – significa una persona que usa la WebApp.",
      },
    },
    changes_of_eula: {
      title: "2. CAMBIOS DEL EULA",
      list: {
        changes_intro:
          "Podemos introducir cambios a este EULA de vez en cuando. Usted debe revisar regularmente la versión más reciente del EULA en el enlace disponible en la página web.",
        changes_effective:
          "Si no está de acuerdo con los términos del EULA actualizado, no podrá usar la WebApp a partir del día en que el EULA actualizado entre en vigor. En este caso, no deberá usar la WebApp y cancelar la renovación de su Suscripción.",
      },
    },
    device: {
      title: "3. DISPOSITIVO",
      list: {
        use_non_owned_device:
          "Si desea utilizar la WebApp en un Dispositivo que no es de su propiedad, debe tener el permiso del propietario para hacerlo. Será responsable de cumplir con este EULA, haya o no poseído el Dispositivo.",
        access_to_non_owned_device:
          "Si no es propietario del Dispositivo, solo podrá usar la WebApp a través de su Cuenta.",
        responsibility:
          "Si usa el Dispositivo junto con otras personas, por favor asegúrese de que nadie más que Usted pueda usar la WebApp desde su Cuenta. Todas las acciones realizadas en su Cuenta se consideran sus acciones, sin importar quién las realice. Usted es totalmente responsable de todas las acciones realizadas en su Cuenta.",
        removal:
          "Si decide vender o disponer del Dispositivo, debe eliminar la WebApp del Dispositivo.",
      },
    },
    capacity_and_age: {
      title: "4. CAPACIDAD LEGAL Y EDAD",
      list: {
        over_16:
          "La WebApp está diseñada para usuarios mayores de 16 años. Si usa la WebApp, confirma que tiene al menos 16 años y plena capacidad legal para celebrar el Acuerdo.",
        between_16_18:
          "Si tiene entre 16 y 18 años, su padre, tutor o cualquier otro representante legal debe revisar y aceptar este EULA en su nombre. Si usa la WebApp, confirma que su padre, tutor o representante legal ha aceptado los términos de este EULA y le permite usar la WebApp.",
      },
    },
    intellectual_property: {
      title: "5. PROPIEDAD INTELECTUAL",
      list: {
        license_grant_and_webapp_use_terms:
          "Por la presente le otorgamos una licencia no exclusiva, personal, limitada, revocable y no transferible para acceder, descargar y ejecutar la WebApp únicamente en su Dispositivo, siempre que acepte cumplir con todos los términos y condiciones de este EULA. Tenga en cuenta que la WebApp se licencia, no se vende, a Usted.",
        intellectual_property_rights_and_reservation_of_rights:
          "La Compañía o el licenciante de la Compañía son los únicos titulares de los Derechos de Propiedad Intelectual con respecto a la WebApp. La Compañía conserva todos los derechos, títulos e intereses sobre la WebApp y se reserva todos los derechos no expresamente otorgados a Usted en este EULA.",
        restrictions_on_transferring_rights_to_webapp:
          "No puede vender, revender, distribuir, redistribuir, alquilar, arrendar, sublicenciar, ceder, copiar ni de ninguna otra manera transferir o otorgar derechos sobre la WebApp a ningún tercero.",
        restrictions_on_network_distribution_and_access:
          "No puede distribuir ni hacer que la WebApp esté disponible a través de ninguna red donde pueda ser descargada o utilizada por varios usuarios al mismo tiempo, salvo que esté expresamente autorizado por Nosotros.",
        prohibitions_on_interfering_with_webapp_functionality:
          "No puede utilizar ningún dispositivo o proceso automático o manual para interferir con el funcionamiento adecuado de la WebApp. Sin embargo, puede utilizar cualquier dispositivo o proceso para eliminar la WebApp de su Dispositivo.",
        prohibitions_on_decompiling_and_reverse_engineering:
          "No puede descompilar, desensamblar, realizar ingeniería inversa ni crear trabajos derivados de la WebApp ni de ninguna de sus partes. Todas las modificaciones o mejoras de la WebApp siguen siendo propiedad exclusiva de la Compañía.",
        prohibitions_on_unlawful_use_and_malicious_activity:
          "No puede usar la WebApp de ninguna manera ilegal, para fines ilegales, ni de ninguna manera incompatible con este EULA ni actuar fraudulentamente o maliciosamente, por ejemplo, al hackear o insertar código malicioso, como virus, o datos dañinos en la WebApp.",
      },
    },
    subscription_and_trial_period: {
      title: "6. SUSCRIPCIÓN Y PERÍODO DE PRUEBA",
      list: {
        subscription_basis_and_fee_variation:
          "Esta WebApp en su totalidad o una parte de ella (Contenido adicional) se proporciona mediante suscripción de pago. La tarifa puede variar dependiendo del período de duración de la Suscripción.",
        automatic_renewal_and_account_charge:
          "La Suscripción se renovará automáticamente por el mismo período al final de la Suscripción al costo del paquete elegido. Su Cuenta será cargada por la renovación de la Suscripción dentro de las 24 horas previas al final de la Suscripción actual.",
        cancellation_of_subscription_renewal:
          "Puede cancelar la renovación de la Suscripción al menos 24 horas antes del final de la Suscripción: en este caso, la WebApp en su totalidad o su Contenido adicional estará disponible para Usted hasta el final de la Suscripción. Para cancelar la renovación automática de la Suscripción, cambie la configuración de suscripción en su Cuenta. Después del final de la Suscripción, la WebApp en su totalidad o su Contenido adicional solo estará disponible si se compra una nueva Suscripción.",
        manual_cancellation_required:
          "Su suscripción no se detendrá automáticamente. Debe cancelar la renovación automática de su suscripción para no ser cargado con el costo de la nueva Suscripción.",
        free_trial_period_and_auto_conversion:
          "La WebApp ofrece un período de prueba gratuito de 7 días. Puede utilizar la WebApp o su Contenido adicional de forma gratuita durante este período. Tenga en cuenta que al final del período de prueba, se convertirá automáticamente en una Suscripción de pago por el período más corto disponible y su Cuenta será cargada por el costo de dicha Suscripción, a menos que cancele la Suscripción a través de la configuración de suscripción en su Cuenta al menos 24 horas antes del final del período de prueba gratuito.",
      },
    },
    fees_and_purchase_terms: {
      title: "7. TARIFAS Y TÉRMINOS DE COMPRA",
      list: {
        subscription_costs_availability:
          "Los costos de todas las Suscripciones están disponibles en la página web.",
        payment_processing_by_platform_administrator:
          "Cuando adquieras una Suscripción, el Administrador de la Plataforma cargará tu Cuenta y recaudará el dinero en nuestro nombre. El pago será procesado por un sistema de procesamiento de pagos implementado por el Administrador de la Plataforma.",
        no_access_to_payment_information:
          "Ten en cuenta que nosotros no procesamos tu pago ni tenemos acceso a tu información de pago, como, entre otros, los datos de tu tarjeta bancaria, tu nombre o cualquier otra información relacionada.",
        questions_on_payment_processing:
          "Si tienes alguna pregunta respecto al procesamiento de pagos, por favor, envía tu consulta al servicio de soporte del Administrador de la Plataforma.",
        account_charge_timing:
          "Tu Cuenta será cargada con el costo de la nueva Suscripción dentro de las 24 horas previas al fin de la Suscripción actual. Si cancelas la renovación automática dentro de las últimas 24 horas de la Suscripción, dicha cancelación entrará en vigor al final de la nueva Suscripción.",
        no_refunds_and_immediate_content_access:
          "Ten en cuenta que no realizamos reembolsos. El contenido digital estará disponible para ti inmediatamente después de que se cargue la tarifa de la Suscripción en tu Cuenta.",
      },
    },
    user_generated_content: {
      title: "8. CONTENIDO GENERADO POR EL USUARIO",
      list: {
        exposure_to_objectionable_content:
          "Dado que puedes acceder a publicaciones de otros usuarios de Instagram, podrías estar expuesto a contenido generado por usuarios potencialmente objetable. Ten en cuenta que la Compañía no controla ni revisa previamente ningún contenido generado por usuarios publicado en ninguna red social.",
        modifications_and_censorship:
          "Cualquier tipo de modificación o censura por parte de la Compañía con respecto al contenido generado por los usuarios podría considerarse una violación de los derechos humanos o de derechos de autor y podría potencialmente inducirte a error.",
        handling_objectionable_content:
          "Si has estado expuesto a cualquier tipo de contenido objetable, puedes usar las funcionalidades de la respectiva red social para rastrear y eliminar el contenido generado por el usuario objetable.",
        company_disclaimer:
          "En ningún caso la Compañía será responsable de cualquier contenido generado por el usuario.",
      },
    },
    disclaimer: {
      title: "9. DESCARGO DE RESPONSABILIDAD",
      list: {
        general_disclaimer_of_warranties:
          "En la máxima medida permitida por la ley aplicable, nosotros y nuestros afiliados, funcionarios, empleados, agentes, socios y licenciantes renunciamos expresamente a todas las garantías de cualquier tipo, ya sean expresas o implícitas, en cuanto a la exactitud, suficiencia o integridad de cualquier información contenida en nuestra WebApp o creada por los servicios.",
        content_provided_as_is:
          "El Contenido en nuestra WebApp te es proporcionado 'tal cual', sin ninguna garantía de comerciabilidad o aptitud para un propósito o uso específico. No garantizamos que alcanzarás el resultado esperado al usar la WebApp.",
        no_warranty_on_specifics: {
          title: "No damos ninguna garantía de que:",
          a: "la WebApp cumpla con tus requisitos;",
          b: "la WebApp esté libre de errores, sea segura o esté ininterrumpida;",
          c: "se corregirán errores en la funcionalidad de la WebApp;",
          d: "la calidad de la WebApp cumpla con tus expectativas.",
        },
        limitation_of_liability:
          "No asumimos responsabilidad ni tenemos ninguna obligación por errores u omisiones. No somos responsables de ningún daño, ya sea directo o indirecto, especial o consecuente, sufrido por ti o por cualquier otra persona debido al uso de la WebApp, incluso si se te ha advertido sobre la posibilidad de tales daños. Ningún consejo ni información, ya sea oral o escrita, obtenida por ti de nosotros o de nuestra WebApp, creará una garantía no expresamente indicada en el EULA. En ningún caso nuestra responsabilidad o la de cualquiera de nuestros afiliados, según cualquier causa de acción, ya sea por contrato, agravio u otra, excederá el monto pagado por ti para acceder a la WebApp. Además, no asumimos ninguna responsabilidad por demoras o fallos debido a circunstancias fuera de nuestro control.",
      },
    },
    transfer_of_rights: {
      title: "10. TRANSFERENCIA DE DERECHOS",
      list: {
        transfer_by_company:
          "Podemos transferir nuestros derechos y obligaciones bajo este EULA a cualquier otra organización o persona sin tu consentimiento. Siempre te informaremos por escrito si esto ocurre y nos aseguraremos de que la transferencia no afecte tus derechos bajo el EULA.",
        transfer_by_user:
          "Solo podrás transferir tus derechos u obligaciones bajo este EULA a otra persona si nosotros lo aprobamos por escrito.",
      },
    },
    termination_of_rights: {
      title: "11. TERMINACIÓN DE DERECHOS",
      list: {
        termination_for_breach:
          "Podemos terminar tus derechos de uso de la WebApp en cualquier momento contactándote si has violado las reglas de este EULA de manera grave. Si lo que has hecho puede ser corregido, te daremos una oportunidad razonable para hacerlo.",
        actions_upon_termination: {
          title: "Si terminamos tus derechos de uso de la WebApp:",
          a: "Deberás detener todas las actividades autorizadas por este EULA, incluyendo tu uso de la WebApp.",
          b: "Deberás eliminar o quitar la WebApp de todos los dispositivos y destruir inmediatamente todas las copias de la WebApp que tengas, y confirmarnos que lo has hecho.",
        },
      },
    },
    miscellaneous: {
      title: "12. MISCELÁNEOS",
      list: {
        separation_of_paragraphs:
          "Cada uno de los párrafos de este EULA opera de manera independiente. Si algún tribunal o autoridad competente decide que alguno de ellos es ilegal, los demás párrafos seguirán siendo válidos y aplicables.",
        delay_in_enforcement:
          "Aunque retrasemos la aplicación de este EULA, aún podemos aplicarlo más tarde. Si no insistimos de inmediato en que hagas algo que se requiere bajo este EULA, o si retrasamos tomar medidas contra ti por haber infringido el EULA, eso no significará que no debas hacer esas cosas ni nos impedirá tomar medidas contra ti en una fecha posterior.",
        governing_law:
          "Este EULA se regirá e interpretará de acuerdo con las leyes de Dublín, Irlanda, sin tener en cuenta sus disposiciones sobre conflicto de leyes.",
        dispute_resolution:
          "Si surge alguna disputa, controversia o diferencia entre tú y la Compañía, se resolverá amistosamente mediante negociaciones. En caso de que dicha disputa, controversia o diferencia no pueda resolverse amistosamente mediante negociaciones dentro de un período de treinta (30) días, se resolverá por la Asociación de Arbitraje de Irlanda de acuerdo con sus Procedimientos Internacionales Acelerados.",
        number_of_arbitrators: "El número de árbitros será uno.",
        place_of_arbitrators: "El lugar de arbitraje será Dublín, Irlanda.",
        language_of_arbitrators:
          "El arbitraje se llevará a cabo y el laudo se emitirá en inglés.",
        expense:
          "Cada parte (es decir, tú y la Compañía) asumirá sus propios gastos, pero las partes compartirán por igual los gastos del tribunal de arbitraje.",
        support:
          "Soporte. Si deseas obtener más información sobre la WebApp o tienes problemas para usarla, por favor, contáctanos en <tag>support@ask-me.app</tag>",
      },
    },
  },
  privacy_policy: {
    title: "Privacidad y Política",
    commitment_title: "Nuestro Compromiso con Tu Privacidad",
    commitment_text:
      "Respetamos y protegemos la privacidad de nuestros usuarios de la WebApp. Esta Política de Privacidad explica cómo recopilamos y utilizamos tu información y forma parte de nuestros Términos de Uso cuando utilizas nuestra WebApp. Al usar nuestra WebApp y los servicios proporcionados a través de la WebApp (“Servicio”), consientes nuestra Política de Privacidad. Si no estás de acuerdo, por favor no uses nuestra WebApp.",
    info_collection_title: "¿Qué información recopilamos?",
    info_collection_text:
      "Si realizas compras o compras una suscripción, nuestros proveedores de servicios de facturación de terceros recopilarán información como tu nombre completo, número de tarjeta de crédito y dirección de facturación. Si nos envías un correo electrónico, podemos usar tu dirección de correo electrónico para responder a tu mensaje. Información enviada por tu dispositivo móvil. Recopilamos cierta información que tu dispositivo móvil envía cuando usas nuestra WebApp, como un identificador de dispositivo, configuraciones del usuario y el sistema operativo de tu dispositivo, así como información sobre tu uso de nuestros Servicios. Información de ubicación. Cuando usas nuestra WebApp, podemos recopilar y almacenar información sobre tu ubicación convirtiendo tu dirección IP en una ubicación geográfica aproximada o accediendo a las coordenadas GPS de tu dispositivo móvil o ubicación aproximada si habilitas los servicios de ubicación en tu dispositivo. Podemos usar la información de ubicación para mejorar y personalizar nuestros Servicios para ti. Si no deseas que recopilemos información de ubicación, puedes deshabilitar esta función en tu dispositivo móvil.",
    info_storage_title: "¿Dónde almacenamos tu información?",
    info_storage_text:
      "Tu información puede ser almacenada en nuestros propios servidores o en servidores propiedad de proveedores de almacenamiento en la nube de terceros. Los proveedores de almacenamiento de terceros no pueden utilizar tu información excepto según lo establecido en esta Política de Privacidad.",
    info_usage_title: "¿Cómo usamos tu información?",
    info_usage_text:
      "La información que recopilamos de ti puede ser utilizada:",
    info_storage_duration_title: "¿Cuánto tiempo almacenamos tu información?",
    info_storage_duration_text:
      "Tenemos la intención de almacenar parte de tu información indefinidamente. ¿Qué pasa con los enlaces a otros sitios web? Podemos proporcionar enlaces o compatibilidad con otros sitios web. Sin embargo, no somos responsables de las prácticas de privacidad de esos sitios web ni de la información o el contenido que contienen.",
    info_protection_title: "¿Cómo protegemos tu información?",
    info_protection_text:
      "Utilizamos procedimientos físicos, electrónicos y otros adecuados para salvaguardar y asegurar la información que recopilamos. Sin embargo, ten en cuenta que Internet es un entorno inherentemente inseguro y que los piratas informáticos están constantemente trabajando para superar las medidas de seguridad. Por lo tanto, no podemos garantizar que tu información no será accedida, divulgada, alterada o destruida, y aceptas este riesgo.",
    user_protection_title: "¿Cómo puedes proteger tu información?",
    user_protection_text:
      "Te instamos a tomar medidas para mantener segura tu información personal, no compartiéndola con otros ni publicándola en línea.",
    disclosure_title: "¿Divulgamos alguna información a terceros?",
    disclosure_text:
      "No recopilamos, vendemos, comerciamos ni transferimos de ninguna otra forma tu Información Personalmente Identificable (PII) a terceros con fines comerciales o de marketing, excepto según lo establecido en este documento. La información personal (y no personal) recopilada a través del Sitio puede ser compartida con empresas y organizaciones que prestan servicios en nuestro nombre (por ejemplo, empresas que brindan servicios de gestión de datos o soporte a nosotros, como almacenamiento de datos y servicios de alojamiento web). Podemos compartir tu información personal (y no personal) con terceros para ofrecerte publicidad relevante y comercializar nuestros productos, pero no venderemos tu información personal. Información divulgada en relación con transacciones comerciales. Si somos adquiridos por un tercero como resultado de una transacción como una fusión, adquisición o venta de activos, o si nuestros activos son adquiridos por un tercero en caso de que salgamos del negocio o entremos en bancarrota, algunos o todos nuestros activos, incluida tu PII, pueden ser divulgados o transferidos a un comprador tercero en relación con la transacción. Información divulgada para nuestra protección y la protección de otros. Cooperamos con funcionarios gubernamentales y de la ley o partes privadas para hacer cumplir y cumplir con la ley. Podemos divulgar cualquier información sobre ti a funcionarios gubernamentales o de la ley o partes privadas según lo consideremos necesario o apropiado: (i) para responder a reclamos, procesos legales (incluidos los citatorios); (ii) para proteger nuestra propiedad, derechos y seguridad y la propiedad, derechos y seguridad de un tercero o del público en general; y (iii) para detener cualquier actividad que consideremos ilegal, poco ética o legalmente procesable.",
    not_for_children_title: "No destinado a niños",
    not_for_children_text:
      "Nuestra WebApp y Servicio no están destinados a niños menores de 16 años. No recopilamos conscientemente ni específicamente información de o sobre niños menores de 16 años.",
    policy_changes_title: "Cambios en nuestra Política de Privacidad",
    policy_changes_text:
      "Si decidimos cambiar nuestra Política de Privacidad, publicaremos esos cambios en esta página. También podemos, pero no estamos obligados, enviarte un aviso por correo electrónico. Te animamos a revisar esta política de privacidad con frecuencia para estar informado sobre cómo estamos protegiendo tu información.",
    contact_title: "Contáctanos",
    contact_text:
      "Si tienes preguntas sobre nuestra Política de Privacidad, por favor contáctanos en <tag>support@ask-me.app</tag>",
  },
};
