import { useRecurly } from "@recurly/react-recurly";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import {
    createSubscription,
    getPrice,
    getRecurlyToken,
    loginUser,
} from "../../services/api/api";
import { generateRandomCode } from "../../utils/utils";
import Button from "../common/Button";
import Loader from "../common/Loader";
// import { loginUser } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import Turnstile from "react-turnstile";
import { routeNames } from "../../router/routes/routeNames";
import { showToast } from "../../utils/toast";
import {
    CardNumberInput,
    CvcInput,
    EmailInput,
    ExpiryInput,
    UserNameInput,
} from "./InputComponents";
import { ApplePayIcon } from "./PaymentMethods";
import { instagram_growth } from "../../assets/content/data";
import { TrueIcon } from "./CoveredPoints";
import { Tooltip } from "react-tooltip";

const applePayOptions = {
    requiredBillingAddressFields: ["all"],
    requiredShippingContactFields: ["phone", "email", "postalAddress"],
    countryCode: "US",
};

const plan_code = "monthlyplan";

const PaymentMethodsP2 = ({ methodVisible, setMethodVisible }) => {
    const [isPaymentProcessing, setPaymentProcessing] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState({
        card: false,
        applePay: false,
    });
    const [appleAvailable, setAppleAvailable] = useState("");
    const [unitPrice, setUnitPrice] = useState(null);
    const [isSupport, setIsSupport] = useState(true);
    const [loading, setLoading] = useState(true);
    const [captchaToken, setCaptchaToken] = useState("");
    const [captchaSiteKey, setCaptchaSiteKey] = useState("");
    const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [error, setError] = useState(null);

    const cardMonthRef = useRef();
    const cardYearRef = useRef();
    const cardCvvRef = useRef();
    const navigate = useNavigate();
    const recurly = useRecurly();
    const risk = recurly.Risk();

    const userNameRef = useRef({
        value: "",
        error: t("Enter Username"),
        touched: false,
    });
    const emailRef = useRef({
        value: "",
        error: t("Enter Email"),
        touched: false,
    });
    const cardNumberRef = useRef({
        value: "",
        error: t("Enter Card Number"),
        touched: false,
    });
    const cardMonthValueRef = useRef({
        value: "",
        error: t("Enter Expiry Month"),
        touched: false,
    });
    const cardYearValueRef = useRef({
        value: "",
        error: t("Enter Expiry Year"),
        touched: false,
    });
    const cvcRef = useRef({
        value: "",
        error: t("Enter CVC"),
        touched: false,
    });

    const applePay = recurly.ApplePay({
        country: "US",
        currency: "USD",
        label: "Ask-Me",
        total: `${unitPrice}`,
    });

    // const applePay = null;

    const toggleMethodVisibility = () => {
        setMethodVisible(!methodVisible);
    };

    const divideStringIntoHalf = (nameString) => {
        const length = nameString?.length;
        const middle = Math.floor(length / 2);
        let result = {};

        result.firstName = nameString?.slice(0, middle);
        result.lastName = nameString?.slice(middle);

        return result;
    };

    const fetchPaymentRequirements = async () => {
        try {
            const response = await getPrice();

            if (response.status === 200) {
                const priceList = response?.data?.data?.plan_list;
                const currentUnitPrice =
                    priceList?.length > 0 &&
                    priceList?.find((plans) => plans.plan_code === plan_code);
                setUnitPrice(currentUnitPrice?.unit_amount);
                setIsCaptchaEnabled(response?.data?.data?.captcha_enable);
                setCaptchaSiteKey(response?.data?.data?.captcha_site_key);
                //   setPaypalPlan(currentUnitPrice?.paypal_code);
                //   setCurrentPlan(currentUnitPrice?.plan_code);
            } else {
                setError("Error fetching payment requirements");
            }
        } catch (error) {
            console.log(error)
            setError("Error fetching payment requirements");
        }
        finally {
            setLoading(false);

        }
    };

    const sendData = async (token) => {
        setLoadingPayment((pre) => ({ ...pre, applePay: true }));
        const code = generateRandomCode();

        try {
            let subscription_payload = {
                email: emailRef?.current?.value?.toLowerCase(),
                token: token.id,
                code: code,
                instagram_username: userNameRef?.current?.value,
                plan_code: plan_code,
                payment_type: "recurly",
            };
            if (isCaptchaEnabled) {
                subscription_payload = {
                    ...subscription_payload,
                    cf_turnstile_response: captchaToken,
                };
            }
            await createSubscription(subscription_payload)
                .then(async (response) => {
                    if (response.status === 200) {
                        await loginUser({
                            email: emailRef?.current?.value?.toLowerCase(),
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    localStorage.setItem("email", emailRef?.current?.value);
                                    showToast(res?.data?.message, "success");
                                    navigate(routeNames.otp, {
                                        state: { isNavigatedFromPayment: true },
                                    });
                                    localStorage.setItem(
                                        "new-user",
                                        JSON.stringify({
                                            email: emailRef?.current?.value?.toLowerCase(),
                                        })
                                    );
                                    setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                                }
                            })
                            .catch((err) => {
                                showToast(err?.response?.data, "error");
                                setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                            });
                    }
                })
                .catch((error) => {
                    showToast(error?.response?.data, "error");
                    setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                });
        } catch (e) {
            setLoadingPayment((pre) => ({ ...pre, applePay: false }));
        }
        setPaymentProcessing(false);
        setLoadingPayment((pre) => ({ ...pre, applePay: false }));
    };

    const handleThreeDSPayment = async (payload) => {
        try {
            setLoadingPayment((pre) => ({ ...pre, card: true }));

            const response = await createSubscription(payload);
            if (response.status === 200) {
                await loginUser({
                    email: emailRef?.current?.value?.toLowerCase(),
                })
                    .then((res) => {
                        if (res.status === 200) {
                            localStorage.setItem(
                                "email",
                                emailRef?.current?.value?.toLowerCase()
                            );
                            showToast(res?.data?.message, "success");
                            navigate(routeNames.otp, {
                                state: { isNavigatedFromPayment: true },
                            });
                            localStorage.setItem(
                                "new-user",
                                JSON.stringify({
                                    email: emailRef?.current?.value?.toLowerCase(),
                                })
                            );
                            setLoadingPayment((pre) => ({ ...pre, card: false }));
                        }
                    })
                    .catch((err) => {
                        showToast(err?.response?.data, "error");
                        setLoadingPayment((pre) => ({ ...pre, card: false }));
                    });
            }
        } catch (error) {
            showToast(
                error?.response?.data?.error ??
                error?.response?.data?.message ??
                error?.response?.data ??
                "Something went wrong",
                "error"
            );
            setLoadingPayment((pre) => ({ ...pre, card: false }));
        }
    };

    const handleAppleClick = (event) => {
        event.preventDefault();

        if (!isSupport) {
            showToast(
                "Your device or browser is not compatible with Apple Pay.",
                "error"
            );
            setLoadingPayment((pre) => ({ ...pre, applePay: false }));
            return;
        } else if (
            isSupport &&
            applePay &&
            emailRef?.current?.value &&
            userNameRef?.current?.value
        ) {
            if (!captchaToken && isCaptchaEnabled) {
                showToast("Please Verify you are human", "error");
                setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                return;
            }

            if (emailRef.current?.error || userNameRef.current?.error) {
                userNameRef.current.touched = true;
                emailRef.current.touched = true;
                return;
            }

            applePay?.ready(function () {
                applePay?.begin();
            });

            applePay.on("error", function (err) {
                console.error("Apple Pay error", err);
            });

            applePay.on("token", async function (token) {
                await sendData(token);
            });
        } else {
            if (!emailRef?.current?.value || !userNameRef?.current?.value) {
                showToast("*", "error");
                userNameRef.current.touched = true;
                emailRef.current.touched = true;
            }
        }
    };

    const handleCardPayment = async (event) => {
        event.preventDefault();

        setLoadingPayment((pre) => ({ ...pre, card: true }));

        if (
            cardNumberRef.current.error ||
            cardMonthValueRef.current.error ||
            cardYearValueRef.current.error ||
            cvcRef.current.error ||
            emailRef.current.error ||
            userNameRef.current.error
        ) {
            setIsClicked(true);
            setLoadingPayment((pre) => ({ ...pre, card: false }));
            return;
        }
        if (!captchaToken && isCaptchaEnabled) {
            showToast("Please Verify you are human", "error");
            setLoadingPayment((pre) => ({ ...pre, card: false }));
            return;
        }
        let showErr = false;
        if (!recurly && showErr) {
            setLoadingPayment((pre) => ({ ...pre, card: false }));
            return;
        }
        const { firstName, lastName } = divideStringIntoHalf(
            emailRef.current?.value?.split("@")[0]
        );
        let formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("number", cardNumberRef.current?.value);
        formData.append("month", cardMonthRef.current?.value);
        formData.append("year", cardYearRef.current?.value);
        formData.append("cvv", cvcRef.current?.value);
        formData.append("email", emailRef.current?.value);
        formData.append("three_d_secure_action_required", true);
        formData.append("key", process.env.REACT_APP_RECURLY_KEY);

        await getRecurlyToken(formData)
            .then((response) => {
                if (
                    !cardNumberRef.current.error &&
                    !cardMonthValueRef.current.error &&
                    !cardYearValueRef.current.error &&
                    !cvcRef.current.error &&
                    !emailRef.current.error &&
                    !userNameRef.current.error &&
                    !response.message
                ) {
                    setLoadingPayment((pre) => ({ ...pre, card: true }));
                    const code = generateRandomCode();
                    const token = response?.data?.id;
                    if (token) {
                        try {
                            let subscription_payload = {
                                email: emailRef.current?.value?.toLowerCase(),
                                token: token,
                                instagram_username: userNameRef.current?.value,
                                code: code,
                                plan_code: plan_code,
                                payment_type: "recurly",
                            };

                            if (isCaptchaEnabled) {
                                subscription_payload = {
                                    ...subscription_payload,
                                    cf_turnstile_response: captchaToken,
                                };
                            }

                            createSubscription(subscription_payload)
                                .then(async (response) => {
                                    if (response.status === 201) {
                                        setLoadingPayment((pre) => ({ ...pre, card: true }));
                                        const threeDSecure = risk.ThreeDSecure({
                                            actionTokenId: response?.data?.action_token_id,
                                        });
                                        threeDSecure.on("error", (err) => {
                                            setLoadingPayment((pre) => ({ ...pre, card: false }));
                                            console.log("threeDSecure err", err);
                                            showToast("Payment Rejected", "error");
                                        });

                                        threeDSecure.on("token", (threeDsToken) => {
                                            setLoadingPayment((pre) => ({ ...pre, card: true }));
                                            let subscription_payload = {
                                                email: emailRef?.current?.value?.toLowerCase(),
                                                token: token,
                                                plan_code: plan_code,
                                                three_d_secure_action_result_token_id: threeDsToken?.id,
                                                code: code,
                                                accountCode: response?.data?.accountCode,
                                                accountId: response?.data?.accountId,
                                                payment_type: "recurly",
                                                instagram_username: userNameRef.current?.value,
                                            };

                                            if (isCaptchaEnabled) {
                                                subscription_payload = {
                                                    ...subscription_payload,
                                                    cf_turnstile_response: captchaToken,
                                                };
                                            }

                                            handleThreeDSPayment(subscription_payload);
                                        });

                                        threeDSecure.attach(document.getElementById("3D-Secure"));
                                    }
                                    if (response.status === 200) {
                                        await loginUser({
                                            email: emailRef?.current?.value?.toLowerCase(),
                                        })
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    localStorage.setItem(
                                                        "email",
                                                        emailRef?.current?.value
                                                    );
                                                    showToast(res?.data?.message, "success");
                                                    navigate(routeNames.otp, {
                                                        state: { isNavigatedFromPayment: true },
                                                    });
                                                    localStorage.setItem(
                                                        "new-user",
                                                        JSON.stringify({
                                                            email: emailRef?.current?.value?.toLowerCase(),
                                                        })
                                                    );
                                                    setLoadingPayment((pre) => ({ ...pre, card: false }));
                                                }
                                            })
                                            .catch((err) => {
                                                showToast(err?.response?.data, "error");
                                                setLoadingPayment((pre) => ({ ...pre, card: false }));
                                            });
                                    }
                                })
                                .catch((error) => {
                                    showToast(
                                        error?.response?.data?.error ??
                                        error?.response?.data?.message ??
                                        error?.response?.data ??
                                        "Something went wrong",
                                        "error"
                                    );
                                    setLoadingPayment((pre) => ({ ...pre, card: false }));
                                });
                        } catch (e) {
                            setLoadingPayment((pre) => ({ ...pre, card: false }));
                            console.log(e.err);
                        }
                    }
                } else {
                    if (
                        response?.message ||
                        (!cardNumberRef?.current?.error &&
                            !cardYearRef?.current?.error &&
                            !cardMonthRef?.current?.error &&
                            !cvcRef?.current?.error)
                    ) {
                        showToast(
                            response?.message || "Card number cannot be a test card number",
                            "error"
                        );
                    }
                    setLoadingPayment((pre) => ({ ...pre, card: false }));
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        recurly.configure({
            applePay: applePayOptions,
        });
    }, [recurly]);

    useEffect(() => {
        if (!window.ApplePaySession) {
            if (appleAvailable !== "Not Available") {
                setIsSupport(false);
                setAppleAvailable("Not Available");
            }
            return;
        }
    }, [isPaymentProcessing]);

    useEffect(() => {
        fetchPaymentRequirements();
    }, []);


    return (
        <div className="payment-section-v">
            <div className={` bg-white payment-section-v-p1 ${methodVisible ? "padding-b-20" : "border-none-b"}`}>
                <div className="per-day-text position-absolute fs-14 open-sans-800 top-0 start-50 translate-middle" >{t("$1.33 Per Day")}</div>
                <p className="fs-20 text-black open-sans-800 mt-4 ">{t("Instagram Growth")}</p>
                <p className="fs-14 text-black fw-regular d-flex align-items-center justify-content-center m-0" >
                    <span className="discount-text">{t("$99")}</span>
                    <span className="fs-24 open-sans-800 price-text px-1">{t("$39.99")} </span>
                    <span>{t("/month")}</span>
                </p>
                <div className="footer-line w-100 payment-line">

                </div>
                <p className="fs-14 growth-sub-text mb-0">
                    {t("The fastest way to grow with advanced targeting, perfect for rapidly expanding influencers and brands.")}
                </p>
            </div>
            {methodVisible ? (
                loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <Loader />
                    </div>
                ) : error ? (
                    <>
                        <p className="fs-14 fw-bold price-error text-center mt-4 pt-2">{error}</p>
                    </>
                ) : (
                    <div className="payment-methods-container mt-3">
                        <div className="email-username-input d-flex flex-column">
                            <UserNameInput inputRef={userNameRef} isClicked={isClicked} />

                            <EmailInput inputRef={emailRef} isClicked={isClicked} />
                        </div>
                        <div className="card-details d-flex flex-column">
                            <CardNumberInput
                                inputRef={cardNumberRef}
                                cardMonthRef={cardMonthRef}
                                isClicked={isClicked}
                            />
                            <div className="d-flex justify-content-between w-100">
                                <div className="expiry-input-wrapper">
                                    <ExpiryInput
                                        cardMonthRef={cardMonthRef}
                                        cardYearRef={cardYearRef}
                                        cardMonthValueRef={cardMonthValueRef}
                                        cardCvvRef={cardCvvRef}
                                        cardYearValueRef={cardYearValueRef}
                                        isClicked={isClicked}
                                    />
                                </div>
                                <div className="cvc-input-wrapper">
                                    <CvcInput
                                        inputRef={cvcRef}
                                        cardCvvRef={cardCvvRef}
                                        isClicked={isClicked}
                                    />
                                    <input
                                        type="hidden"
                                        name="recurly-token"
                                        data-recurly="token"
                                    />
                                </div>
                            </div>
                        </div>
                        {isCaptchaEnabled && (
                            <Turnstile
                                theme="light"
                                className="turnstile-custom"
                                fixedSize={true}
                                sitekey={captchaSiteKey}
                                onVerify={(token) => setCaptchaToken(token)}
                            />
                        )}
                        {loadingPayment?.card ? (
                            <div className="w-100 d-flex justify-content-center py-3">
                                <Loader />
                            </div>
                        ) : (
                            <Button
                                onClick={handleCardPayment}
                                // onClick={login}
                                label={t("Process Credit Card")}
                                hideArrow
                            />
                        )}
                        <p className="or-text text-black text-uppercase text-center fs-14 fw-regular">
                            {t("OR")}
                        </p>
                        {loadingPayment?.applePay ? (
                            <div className="w-100 d-flex justify-content-center py-3">
                                <Loader />
                            </div>
                        ) : (
                            <button
                                className="payment-btn apple-pay-btn w-100"
                                onClick={handleAppleClick}
                            // onClick={login}
                            // onClick={() => showToast("as")}
                            >
                                <ApplePayIcon />
                            </button>
                        )}
                        <div
                            id="3D-Secure"
                            className="threeDSecure"
                            style={{
                                maxWidth: "390px",
                            }}
                        ></div>
                    </div>
                )
            ) : (
                <div className="payment-section-v-p1 border-none-t bg-white">
                    <Button
                        label={t("Get My Plan")}
                        onClick={toggleMethodVisibility}
                    />
                    <div className="growth-list-container d-flex flex-column">
                        {

                            instagram_growth.map((item, index) => {
                                return (
                                    < div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <TrueIcon />
                                            <p className="fs-12 text-black checklist-text text-start">{item?.title}</p>
                                        </div>
                                        <a data-tooltip-id={`tooltip-${index}`} id={`tooltip-${index}`}  ><ToolTip /></a>
                                        <Tooltip afterShow={() => {
                                            document.getElementById(`tooltip-${index}`).classList.add("tooltip-anchor-payment-active")

                                        }} afterHide={() => {
                                            document.getElementById(`tooltip-${index}`).classList.remove("tooltip-anchor-payment-active")

                                        }}
                                            style={{ backgroundColor: '#707070', color: 'white', fontSize: 12, opacity: 1, borderRadius: 5 }} id={`tooltip-${index}`} place="top-start" className="max-w-dvw" html={item?.tooltipText} events={['click']} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </div>
    );
};


export const ToolTip = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M16.563,8.563a8,8,0,1,1-8-8A8,8,0,0,1,16.563,8.563ZM8.777,3.208a4.178,4.178,0,0,0-3.76,2.057.388.388,0,0,0,.088.524l1.119.849a.387.387,0,0,0,.538-.068c.576-.731.971-1.155,1.848-1.155.659,0,1.474.424,1.474,1.063,0,.483-.4.731-1.049,1.1C8.276,8,7.272,8.528,7.272,9.853v.129a.387.387,0,0,0,.387.387H9.466a.387.387,0,0,0,.387-.387V9.939c0-.918,2.683-.956,2.683-3.441C12.536,4.627,10.6,3.208,8.777,3.208Zm-.215,8a1.484,1.484,0,1,0,1.484,1.484A1.486,1.486,0,0,0,8.563,11.208Z" transform="translate(-0.563 -0.563)" fill="#e1e1e1" />
        </svg>
    )
}


export const ActiveToolTip = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M16.563,8.563a8,8,0,1,1-8-8A8,8,0,0,1,16.563,8.563ZM8.777,3.208a4.178,4.178,0,0,0-3.76,2.057.388.388,0,0,0,.088.524l1.119.849a.387.387,0,0,0,.538-.068c.576-.731.971-1.155,1.848-1.155.659,0,1.474.424,1.474,1.063,0,.483-.4.731-1.049,1.1C8.276,8,7.272,8.528,7.272,9.853v.129a.387.387,0,0,0,.387.387H9.466a.387.387,0,0,0,.387-.387V9.939c0-.918,2.683-.956,2.683-3.441C12.536,4.627,10.6,3.208,8.777,3.208Zm-.215,8a1.484,1.484,0,1,0,1.484,1.484A1.486,1.486,0,0,0,8.563,11.208Z" transform="translate(-0.563 -0.563)" fill="#66ae08" />
        </svg>

    )
}

export default PaymentMethodsP2