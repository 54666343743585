import i18next, { t } from "i18next";
import React, { useEffect, useState } from "react";

const Timer = () => {
  const [time, setTime] = useState({ minutes: 14, seconds: 59 });

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTime((prevTime) => {
        let { minutes, seconds } = prevTime;

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timerInterval);
            return { minutes: 0, seconds: 0 };
          }
          minutes -= 1;
          seconds = 59;
        } else {
          seconds -= 1;
        }

        return { minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formatTime = (timeUnit) => String(timeUnit).padStart(2, "0");

  return (
    <div className="w-100 grad-blue-pink d-flex align-items-center timer-v-padding timer">
      <div className="d-flex align-items-center timer-content-w justify-content-center">
        <TimerIcon />
        <div className="sale-text">
          <p
            className={`text-white m-0 ${
              i18next.language === "es" ? "fs-12" : "fs-14"
            } fw-bold text-uppercase`}
          >
            {t("90% OFF")}
          </p>
          <p className="text-white m-0 fs-12 fw-regular text-uppercase">
            {t("FLASH SALE")}
          </p>
        </div>
      </div>
      <div className="hl-2px"></div>
      <div className="d-flex align-items-center justify-content-center timer-content-w">
        <p className="text-white m-0 fs-20 open-sans-800">
          00 : {formatTime(time.minutes)} : {formatTime(time.seconds)}
        </p>
      </div>
    </div>
  );
};

const TimerIcon = () => (
  <svg
    id="timer"
    xmlns="http://www.w3.org/2000/svg"
    width="39.999"
    height="32.506"
    viewBox="0 0 39.999 32.506"
  >
    <path
      id="Path_109"
      data-name="Path 109"
      d="M222.074,119a1.172,1.172,0,0,1,1.172-1.172h1.143a9.567,9.567,0,0,0-8.3-8.3v1.143a1.172,1.172,0,1,1-2.344,0V109.52a9.567,9.567,0,0,0-8.3,8.3h1.143a1.172,1.172,0,0,1,0,2.344h-1.143a9.567,9.567,0,0,0,8.3,8.3v-1.143a1.172,1.172,0,1,1,2.344,0v1.143a9.567,9.567,0,0,0,8.3-8.3h-1.143A1.172,1.172,0,0,1,222.074,119Zm-3.813,3.347a1.172,1.172,0,0,1-1.657,0l-2.519-2.519a1.172,1.172,0,0,1-.343-.829V115.9a1.172,1.172,0,1,1,2.344,0v2.608l2.176,2.176a1.172,1.172,0,0,1,0,1.657Zm0,0"
      transform="translate(-189.388 -100.964)"
      fill="#fff"
    />
    <path
      id="Path_110"
      data-name="Path 110"
      d="M157.1,3.605V2.344h.252a1.172,1.172,0,0,0,0-2.344h-2.849a1.172,1.172,0,1,0,0,2.344h.252V3.605a14.474,14.474,0,1,0,2.344,0ZM155.93,29.923a11.891,11.891,0,1,1,11.891-11.891A11.9,11.9,0,0,1,155.93,29.923Zm0,0"
      transform="translate(-130.405)"
      fill="#fff"
    />
    <path
      id="Path_111"
      data-name="Path 111"
      d="M33.475,142.821H29.109a1.172,1.172,0,1,1,0-2.344h4.365a1.172,1.172,0,1,1,0,2.344Zm0,0"
      transform="translate(-25.755 -129.502)"
      fill="#fff"
    />
    <path
      id="Path_112"
      data-name="Path 112"
      d="M7.72,204.907H1.172a1.172,1.172,0,0,1,0-2.344H7.72a1.172,1.172,0,0,1,0,2.344Zm0,0"
      transform="translate(0 -186.738)"
      fill="#fff"
    />
    <path
      id="Path_113"
      data-name="Path 113"
      d="M33.475,266.992H29.109a1.172,1.172,0,1,1,0-2.344h4.365a1.172,1.172,0,1,1,0,2.344Zm0,0"
      transform="translate(-25.755 -243.973)"
      fill="#fff"
    />
  </svg>
);

export default Timer;
