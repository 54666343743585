import { t } from "i18next";
import debounce from "lodash.debounce";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DefaultProfile from "../../assets/images/profile/default_profile.jpg";
import "../../assets/styles/pages/activity.css";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import { loadData } from "../../redux/slices/activity";
import { checkUserName, updateUserName } from "../../services/api/api";
import { isValidObject } from "../../utils/utils";
import Resubscribe from "../../components/payment/Resubscribe";

const MonthGraph = React.lazy(() =>
  import("../../components/activity/MonthGraph")
);

const WeekGraph = React.lazy(() =>
  import("../../components/activity/WeekGraph")
);

const Skeleton = ({ className }) => (
  <div className={`skeleton-box ${className || ""}`}></div>
);

const WrapSkeleton = ({ loading, className, children }) =>
  loading ? <Skeleton className={className} /> : <>{children}</>;

const ErrorText = ({ message }) => (
  <div className="error-wrapper d-flex justify-content-center align-items-center">
    <p className="error-text m-0 fs-12">{message}</p>
  </div>
);

const Activity = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [textInput, setTextInput] = useState("");
  const [loader, setLoader] = useState(true);
  const { data, loading, error } = useSelector((state) => state.activityState);

  const dispatch = useDispatch();

  const handleUsernameChange = (e) => {
    setTextInput(e.target.value);
    debouncedCheckUserName(e.target.value);
  };

  const debouncedCheckUserName = useCallback(
    debounce(async (username) => {
      setLoader(true);
      try {
        await checkUserName(username);
        setErrorMessage("");
      } catch (error) {
        setErrorMessage(
          t("Invalid instagram username") ?? "Internal Server Error"
        );
      } finally {
        setLoader(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedCheckUserName.cancel();
    };
  }, [debouncedCheckUserName]);

  const handleSubmitUserName = async () => {
    if (!errorMessage && textInput) {
      try {
        const response = await updateUserName(textInput);

        dispatch(loadData());
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <div className="common-padding-x common-padding-y flex-grow-1 d-flex flex-column">
      <div className="flex-grow-1">
        {/* <Resubscribe /> */}
        {isValidObject(data) && !data?.is_user_name_valid ? (
          <div className="red-background profile-wrapper d-flex flex-column justify-content-around align-items-center text-center text-white">
            <p className="fw-bold fs-14 warning-heading">{t("Warning")}</p>
            <p className="fw-regular fs-14 warning-text">
              <Trans
                i18nKey={
                  "Your Instagram username is incorrect, and we cannot start your order. Please update it so we can proceed."
                }
                components={{ tag: <br /> }}
              />
            </p>
            <div className="w-100">
              <input
                onChange={handleUsernameChange}
                value={textInput}
                className="username-input"
                placeholder={t("Instagram Username")}
              />{" "}
            </div>
            <div className="username-error fs-14 mt-1">
              {" "}
              {loader && textInput?.length > 0 ? (
                <div
                  style={{ height: "18px", width: "18px" }}
                  className="spinner-border"
                  role="status"
                >
                  <span class="sr-only"></span>
                </div>
              ) : (
                <div></div>
              )}{" "}
              <span>{errorMessage}</span>
            </div>

            <Button
              onClick={handleSubmitUserName}
              className={`${
                !errorMessage && textInput
                  ? "update-button-green "
                  : "update-button"
              }  mt-3`}
              label={t("Update")}
            />
          </div>
        ) : (
          <div className="grad-blue-purple-orange profile-wrapper d-flex justify-content-around align-items-center text-center text-white">
            <div>
              <WrapSkeleton
                className={"skeleton-followers-text"}
                loading={loading}
              >
                <p className="open-sans-800 fs-20 ls-20 m-0">
                  {data.total_followers || 0}
                </p>
              </WrapSkeleton>
              <p className="fw-regular fs-14 m-0">{t("Followers")}</p>
            </div>
            <div className="profile-img-wrapper d-flex justify-content-center align-items-center">
              <img
                height={79}
                width={79}
                src={
                  data?.profile_image_url?.startsWith("data:image")
                    ? data.profile_image_url
                    : `data:image/jpeg;base64,${data?.profile_image_url}` ||
                      "/images/profile.png"
                }
                alt={data.full_name || "Profile"}
                onError={(e) => {
                  e.target.src = DefaultProfile;
                  e.target.onerror = null;
                }}
                className="profile-img w-100"
              />
            </div>
            <div>
              <WrapSkeleton
                className={"skeleton-followers-text"}
                loading={loading}
              >
                <p className="open-sans-800 fs-20 ls-20 m-0">
                  {data.total_following || 0}
                </p>
              </WrapSkeleton>
              <p className="fw-regular fs-14 m-0">{t("Following")}</p>
            </div>
          </div>
        )}

        <div className="common-padding-y pb-0">
          {data?.is_user_name_valid && (
            <>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <p className="open-sans-800 fs-14 ls-20 text-black m-0">
                    {t("7-Day Activity")}
                  </p>
                  <div className="fw-bold fs-10 text-right d-flex justify-content-right align-items-center high-low-wrapper">
                    <p className="high-text  d-flex justify-content-center align-items-center m-0">
                      <span>
                        <UpIcon />
                      </span>
                      <WrapSkeleton
                        className={"skeleton-text"}
                        loading={loading}
                      >
                        <span>
                          {data?.weekly_activity_summary?.positive_interactions}
                        </span>
                      </WrapSkeleton>
                    </p>
                    <p className="low-text d-flex justify-content-center align-items-center m-0">
                      <span>
                        <DownIcon />
                      </span>
                      <WrapSkeleton
                        className={"skeleton-text"}
                        loading={loading}
                      >
                        <span>
                          {data?.weekly_activity_summary?.negative_interactions}
                        </span>
                      </WrapSkeleton>
                    </p>
                  </div>
                </div>
                <div className="activity-line w-100" />
                <WrapSkeleton
                  className={"skeleton-graph-wrapper"}
                  loading={loading}
                >
                  <Suspense
                    fallback={<Skeleton className="skeleton-graph-wrapper" />}
                  >
                    {error ? <ErrorText {...error} /> : <WeekGraph />}
                  </Suspense>
                </WrapSkeleton>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <p className="open-sans-800 fs-14 ls-20 text-black m-0">
                    {t("30-Day Activity")}
                  </p>
                  <div className="fw-bold fs-10 text-right d-flex justify-content-right align-items-center high-low-wrapper">
                    <p className="high-text  d-flex justify-content-center align-items-center m-0">
                      <span>
                        <UpIcon />
                      </span>
                      <WrapSkeleton
                        className={"skeleton-text"}
                        loading={loading}
                      >
                        <span>
                          {
                            data?.monthly_activity_summary
                              ?.positive_interactions
                          }
                        </span>
                      </WrapSkeleton>
                    </p>
                    <p className="low-text d-flex justify-content-center align-items-center m-0">
                      <span>
                        <DownIcon />
                      </span>
                      <WrapSkeleton
                        className={"skeleton-text"}
                        loading={loading}
                      >
                        <span>
                          {
                            data?.monthly_activity_summary
                              ?.negative_interactions
                          }
                        </span>
                      </WrapSkeleton>
                    </p>
                  </div>
                </div>
                <div className="activity-line w-100" />
                <WrapSkeleton
                  className={"skeleton-graph-wrapper"}
                  loading={loading}
                >
                  <Suspense
                    fallback={<Skeleton className="skeleton-graph-wrapper" />}
                  >
                    {error ? <ErrorText {...error} /> : <MonthGraph />}
                  </Suspense>
                </WrapSkeleton>
              </div>
            </>
          )}
          <div
            className="graph-wrapper text-center"
            style={{ paddingBottom: 13 }}
          >
            <div className="px-1">
              <p className="warning-title open-sans-800 fs-14 ls-20 m-0">
                {t("Account Settings")}
              </p>
              <p className="fs-14 fw-regular m-0">
                <Trans
                  i18nKey={"Ensure your account is set to PUBLIC"}
                  components={{ tag: <b /> }}
                />
              </p>
            </div>
            <div
              className="activity-line w-100 my-2"
              style={{ backgroundColor: "#ACACAC" }}
            />
            <div className="px-1">
              <p className="warning-title open-sans-800 fs-14 ls-20 m-0">
                {t("Important")}
              </p>
              <p className="fs-14 fw-regular m-0">
                <Trans
                  i18nKey={
                    "Please make sure to TURN OFF the 'Flag for Review' option. You can find it in the Follower and Invite Friends settings in the Instagram app. This will help ensure everything runs smoothly."
                  }
                  components={{ tag: <b /> }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const UpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
      fill="#66ae08"
    />
  </svg>
);

const DownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
      transform="translate(10 8) rotate(180)"
      fill="#ee1e52"
    />
  </svg>
);
export default Activity;
