import { t } from "i18next";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";

const checkError = (isClicked, error, touched) =>
  isClicked && error ? error : touched && error ? error : "";

export const UserNameInput = ({ inputRef, isClicked }) => {
  const [inputState, setInputState] = useState({
    value: "",
    error: t("Enter Username"),
    touched: false,
  });

  const debouncedUpdate = useRef(
    debounce((value) => {
      if (inputRef) {
        inputRef.current = value;
      }
    }, 500)
  );

  const handleUsernameChange = (e) => {
    const value = e.target.value?.trim();
    setInputState((prev) => ({
      ...prev,
      value: value?.trim()?.toLowerCase(),
      error: null,
      touched: true,
    }));
    debouncedUpdate.current({
      value: value?.toLowerCase(),
      error: !value
        ? t("Enter Username")
        : !/^(?!\d+$)[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/.test(value)
        ? t("Username is incorrect")
        : "",
      touched: true,
    });
  };

  const handleErrorUserNameCheck = (e) => {
    const usernameRegex = /^(?!\d+$)[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/;
    const error = !e.target.value
      ? t("Enter Username")
      : !usernameRegex.test(e.target.value)
      ? t("Username is incorrect")
      : "";
    setInputState((prev) => ({ ...prev, error, touched: true }));
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.current.cancel();
    };
  }, []);

  useEffect(() => {
    inputState.touched !== inputRef?.current?.touched &&
      setInputState((prev) => ({
        ...prev,
        touched: inputRef?.current?.touched,
      }));
  }, [inputRef?.current?.touched]);

  return (
    <div className="d-flex flex-column position-relative">
      <label
        className="payment-details-label fs-14 text-black fw-regular"
        htmlFor={"userNameLabel"}
      >
        {t("Instagram Username")}
      </label>
      <input
        className={`payment-details-input ${
          checkError(isClicked, inputState.error, inputState.touched)
            ? "card-details-error"
            : ""
        }`}
        name={t("Instagram Username") + "label"}
        onChange={handleUsernameChange}
        value={inputState.value}
        onBlur={handleErrorUserNameCheck}
        onFocus={() => setInputState((prev) => ({ ...prev, error: "" }))}
      />
      <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
        {checkError(isClicked, inputState.error, inputState.touched)}
      </span>
    </div>
  );
};

export const EmailInput = ({ inputRef, isClicked }) => {
  const [inputState, setInputState] = useState({
    value: "",
    error: t("Enter Email"),
    touched: false,
  });

  const debouncedUpdate = useRef(
    debounce((value) => {
      if (inputRef) {
        inputRef.current = value;
      }
    }, 500)
  );

  const handleEmailChange = (e) => {
    setInputState((prev) => ({
      ...prev,
      value: e.target.value,
      error: null,
      touched: true,
    }));
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const error = !emailRegex.test(e.target.value) ? t("Invalid Email") : "";
    debouncedUpdate.current({
      value: e.target.value,
      error,
      touched: true,
    });
  };

  const handleErrorEmailCheck = (e) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const error = !emailRegex.test(e.target.value) ? t("Invalid Email") : "";
    setInputState((prev) => ({ ...prev, error, touched: true }));
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.current.cancel();
    };
  }, []);

  useEffect(() => {
    inputState.touched !== inputRef?.current?.touched &&
      setInputState((prev) => ({
        ...prev,
        touched: inputRef?.current?.touched,
      }));
  }, [inputRef?.current?.touched]);

  return (
    <div className="d-flex flex-column position-relative">
      <label
        className="payment-details-label fs-14 text-black fw-regular"
        htmlFor={t("Email") + "label"}
      >
        {t("Email")}
      </label>
      <input
        className={`payment-details-input ${
          checkError(isClicked, inputState.error, inputState.touched)
            ? "card-details-error"
            : ""
        }`}
        name={t("Email") + "label"}
        type="email"
        onChange={handleEmailChange}
        onFocus={() => setInputState((prev) => ({ ...prev, error: "" }))}
        onBlur={handleErrorEmailCheck}
        value={inputState.value}
      />
      <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
        {checkError(isClicked, inputState.error, inputState.touched)}
      </span>
    </div>
  );
};

export const CardNumberInput = ({ inputRef, cardMonthRef, isClicked }) => {
  const [inputState, setInputState] = useState({
    value: "",
    error: t("Enter Card Number"),
    touched: false,
  });

  const debouncedUpdate = useRef(
    debounce((value) => {
      if (inputRef) {
        inputRef.current = value;
      }
    }, 500)
  );

  const handleCardNumberChange = (e) => {
    const inputVal = e.target.value;
    const inputCardNumber = e.target.value.replace(/\D/g, "");
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, "$1 ")
      .trim();

    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;
    const error =
      !inputVal || length < 15 || length > 16 ? t("Invalid Card Number") : "";

    if (length === 16 || length > 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }

    setInputState({
      value: formattedCardNumber,
      error,
      touched: true,
    });
    debouncedUpdate.current({
      value: formattedCardNumber,
      error,
      touched: true,
    });
  };

  const handleErrorCardNumber = (e) => {
    const inputVal = e.target.value;
    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;
    const error =
      !inputVal || length < 15 || length > 16 ? t("Invalid Card Number") : "";

    if (length === 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }

    setInputState((prev) => ({ ...prev, error, touched: true }));
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.current.cancel();
    };
  }, []);

  useEffect(() => {
    inputState.touched !== inputRef?.current?.touched &&
      setInputState((prev) => ({
        ...prev,
        touched: inputRef?.current?.touched,
      }));
  }, [inputRef?.current?.touched]);

  return (
    <div className="d-flex flex-column position-relative">
      <label
        className="payment-details-label fs-14 text-black fw-regular"
        htmlFor={t("Card Number") + "label"}
      >
        {t("Card Number")}
      </label>
      <input
        className={`payment-details-input ${
          checkError(isClicked, inputState.error, inputState.touched)
            ? "card-details-error"
            : ""
        }`}
        type="text"
        data-recurly={"number"}
        id="cardNumber"
        placeholder="xxxx xxxx xxxx xxxx"
        inputMode="numeric"
        autoComplete="off"
        name={t("Card Number") + "label"}
        value={inputState.value}
        onChange={handleCardNumberChange}
        onBlur={handleErrorCardNumber}
        onFocus={() => setInputState((prev) => ({ ...prev, error: "" }))}
      />
      <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
        {checkError(isClicked, inputState.error, inputState.touched)}
      </span>
    </div>
  );
};

export const CvcInput = ({ inputRef, cardCvvRef, isClicked }) => {
  const [inputState, setInputState] = useState({
    value: "",
    error: t("Enter CVC"),
    touched: false,
  });

  const debouncedUpdate = useRef(
    debounce((value) => {
      if (inputRef) {
        inputRef.current = value;
      }
    }, 500)
  );

  const handleCardCvvChange = (e) => {
    const length = e.target.value.length;
    // const error = length < 3 ? t("Invalid CVC") : "";
    setInputState({
      value: e.target.value,
      error: "",
      touched: true,
    });

    debouncedUpdate.current({
      value: e.target.value,
      error: "",
      touched: true,
    });
  };

  const handleErrorCardCvv = (e) => {
    const length = e.target.value.length;
    const error = length < 3 ? t("Invalid CVC") : "";
    setInputState((prev) => ({ ...prev, error, touched: true }));
    debouncedUpdate.current({
      value: e.target.value,
      error,
      touched: true,
    });
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.current.cancel();
    };
  }, []);

  useEffect(() => {
    inputState.touched !== inputRef?.current?.touched &&
      setInputState((prev) => ({
        ...prev,
        touched: inputRef?.current?.touched,
      }));
  }, [inputRef?.current?.touched]);

  return (
    <div className="d-flex flex-column position-relative">
      <label
        className="payment-details-label fs-14 text-black fw-regular"
        htmlFor={t("CVC") + "label"}
      >
        {t("CVC")}
      </label>
      <input
        ref={cardCvvRef}
        className={`payment-details-input text-center ${
          checkError(isClicked, inputState.error, inputState.touched)
            ? "card-details-error"
            : ""
        }`}
        name={t("CVC") + "label"}
        placeholder="XXX"
        id="cardYear"
        type="text"
        data-recurly={"cvv"}
        inputMode="numeric"
        autoComplete="off"
        maxLength={4}
        value={inputState.value}
        onChange={handleCardCvvChange}
        onBlur={handleErrorCardCvv}
        onFocus={() => setInputState((prev) => ({ ...prev, error: "" }))}
      />
      <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
        {checkError(isClicked, inputState.error, inputState.touched)}
      </span>
    </div>
  );
};

export const ExpiryInput = ({
  // handleCardMonthChange,
  // handleCardYearChange,
  // handleErrorMonthChange,
  // handleErrorYearChange,
  cardMonthRef,
  cardYearRef,
  cardYearValueRef,
  cardMonthValueRef,
  cardCvvRef,
  isClicked,
  // monthError,
  // yearError,
  // monthValue,
  // yearValue,
  // handleError,
  // ...props
}) => {
  const currentYear = new Date().getFullYear().toString().slice(2, 4);
  const [cardMonth, setCardMonth] = useState({
    value: "",
    error: t("Enter Expiry Month"),
    touched: false,
  });
  const [cardYear, setCardYear] = useState({
    value: "",
    error: t("Enter Expiry Year"),
    touched: false,
  });

  const debouncedMonthUpdate = useRef(
    debounce((value) => {
      if (cardMonthValueRef) {
        cardMonthValueRef.current = value;
      }
    }, 500)
  );
  const debouncedYearUpdate = useRef(
    debounce((value) => {
      if (cardYearValueRef) {
        cardYearValueRef.current = value;
      }
    }, 500)
  );

  const handleCardMonthChange = (e) => {
    const length = e.target.value.length;
    // handleValue("cardMonth", e.target.value);
    const error = length < 2 || e.target.value > 12 ? t("Invalid Month") : "";
    // handleError("cardMonth", "");

    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }

    // !touchedDetails.cardMonth && handleTouched("cardMonth", true);
    setCardMonth({
      value: e.target.value,
      error: "",
      touched: true,
    });
    debouncedMonthUpdate.current({
      value: e.target.value,
      error: "",
      touched: true,
    });
  };

  const handleCardYearChange = (e) => {
    const length = e.target.value.length;
    // handleValue("cardYear", e.target.value);
    // const error =
    //   length < 2 || e.target.value < currentYear ? t("Invalid Year") : "";
    // handleError("cardYear", "");

    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }

    // !touchedDetails.cardYear && handleTouched("cardYear", true);

    setCardYear({
      value: e.target.value,
      error: "",
      touched: true,
    });
    debouncedYearUpdate.current({
      value: e.target.value,
      error: "",
      touched: true,
    });
  };

  const handleErrorMonthChange = (e) => {
    const length = e.target.value.length;
    const error = length < 2 || e.target.value > 12 ? t("Invalid Month") : "";
    // handleError("cardMonth", error);

    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }

    // !touchedDetails.cardMonth && handleTouched("cardMonth", true);

    setCardMonth({
      value: e.target.value,
      error,
      touched: true,
    });
    debouncedMonthUpdate.current({
      value: e.target.value,
      error,
      touched: true,
    });
  };

  const handleErrorYearChange = (e) => {
    const length = e.target.value.length;
    const error =
      length < 2 || e.target.value < currentYear ? t("Invalid Year") : "";
    // handleError("cardYear", error);

    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }

    // !touchedDetails.cardYear && handleTouched("cardYear", true);

    setCardYear({
      value: e.target.value,
      error,
      touched: true,
    });
    debouncedYearUpdate.current({
      value: e.target.value,
      error,
      touched: true,
    });
  };

  useEffect(() => {
    return () => {
      debouncedMonthUpdate.current.cancel();
      debouncedYearUpdate.current.cancel();
    };
  }, []);

  useEffect(() => {
    cardMonth.touched !== cardMonthValueRef?.current?.touched &&
      setCardMonth((prev) => ({
        ...prev,
        touched: cardMonthValueRef?.current?.touched,
      }));
    cardYear.touched !== cardYearValueRef?.current?.touched &&
      setCardYear((prev) => ({
        ...prev,
        touched: cardYearValueRef?.current?.touched,
      }));
  }, [cardMonthValueRef?.current?.touched, cardYearValueRef?.current?.touched]);

  return (
    <div className="d-flex flex-column position-relative">
      <label
        className="payment-details-label fs-14 text-black fw-regular"
        htmlFor="cardMonth"
      >
        {t("Expires on")}
      </label>
      <div className="d-flex justify-content-center gap-2 pe-4">
        <input
          className={`payment-details-input w-100 text-center ${
            checkError(isClicked, cardMonth.error, cardMonth.touched)
              ? "card-details-error"
              : ""
          }`}
          placeholder="MM"
          inputMode="numeric"
          autoComplete="off"
          id="cardMonth"
          name="cardMonth"
          type="text"
          data-recurly={"month"}
          maxLength={2}
          onChange={handleCardMonthChange}
          onBlur={handleErrorMonthChange}
          onFocus={() => setCardMonth((prev) => ({ ...prev, error: "" }))}
          ref={cardMonthRef}
          value={cardMonth.value}
        />
        <input
          className={`payment-details-input w-100 text-center ${
            checkError(isClicked, cardYear.error, cardYear.touched)
              ? "card-details-error"
              : ""
          }`}
          placeholder="YY"
          inputMode="numeric"
          autoComplete="off"
          id="cardYear"
          name="cardYear"
          type="text"
          maxLength={2}
          data-recurly={"year"}
          onChange={handleCardYearChange}
          onBlur={handleErrorYearChange}
          onFocus={() => setCardYear((prev) => ({ ...prev, error: "" }))}
          ref={cardYearRef}
          value={cardYear.value}
        />
      </div>
      <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
        {checkError(isClicked, cardMonth.error, cardMonth.touched) ||
          checkError(isClicked, cardYear.error, cardYear.touched)}
      </span>
    </div>
  );
};
