import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const recurlyUrl = axios.create({
  baseURL: `${process.env.REACT_APP_RECURLY_URL}`,
});

function getToken() {
  const data = localStorage.getItem("token");
  if (data !== null && data !== undefined) {
    const access = JSON.parse(data);
    return access.token;
  } else {
    return null;
  }
}

export const getPrice = () => api.get(`${API_URL}/get-price`);

export const getRecurlyToken = (data = {}) =>
  recurlyUrl
    .post("token", data, {})
    .then(
      (response) => {
        if (response.status === 200) {
          if (response.data.error) {
            return response.data.error;
          }
          return response;
        }
      },
      (err) => {
        if (err.response.status === 401) {
        }
        return err.response;
      }
    )
    .catch((reason) => {
      return reason;
    });

export const createSubscription = (data) =>
  api.post(`${API_URL}/create-subscription/`, data);

export const loginUser = (data = {}) => {
  return api.post(`${API_URL}/login-with-otp/`, data);
};

export const getActivityData = (data = {}) => {
  const headers = {
    Authorization: `Token ${getToken()}`,
  };
  return api.get(`${API_URL}/get-instagram-detail/`, { headers });
};

export const checkUserName = (instagram_username) => {
  const headers = {
    Authorization: `Token ${getToken()}`,
  };

  const result = api.get(`${API_URL}/check-instagram-user-name/`, {
    params: { instagram_username },
    headers,
  });

  return result
};



export const updateUserName = (instagram_username) => {
  const headers = {
    Authorization: `Token ${getToken()}`,
  };

  return api.post(
    `${API_URL}/update-instagram-user-name/`,
    { instagram_username }, 
    { headers } 
  );
};



export const verifyOTP = (data = {}) =>
  api.post(`${API_URL}/validate-otp/`, data);

export const updateInstagramDetails = (data = {}) => {
  const headers = {
    Authorization: `Token ${data?.token || getToken()}`,
  };

  return api.get(`${API_URL}/update-instagram-detail/`, { headers });
};
