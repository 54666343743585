import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { support_email } from "../../assets/content/data";
import "../../assets/styles/pages/subscription.css";
import Footer from "../../components/common/Footer";
import { routeNames } from "../../router/routes/routeNames";

const Subscription = () => {
  return (
    <div className="common-padding-x common-padding-y flex-grow-1 d-flex flex-column">
      <div className="d-flex justify-content-start align-items-center subscription-text gap-3">
        <Link to={routeNames.landing}>
          <BackIcon />
        </Link>
        <p className="fs-20 text-black open-sans-800 m-0">
          {t("Manage Subscription")}
        </p>
      </div>
      <p className="active-wrapper grad-blue-pink text-start open-sans-800 text-white">
        {t("Active")}
      </p>
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center contact-text">
        <p className="text-center fs-16 fw-regular text-black">
          <Trans
            i18nKey={
              "Do you want to cancel? Reach our friendly support team here"
            }
            components={{
              br: <br />,
              tag: (
                <Link to={`mailto:${support_email}`} className="text-blue" />
              ),
            }}
          />
        </p>
      </div>
      <Footer />
    </div>
  );
};

const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.25"
    height="29.25"
    viewBox="0 0 29.25 29.25"
  >
    <g
      id="Icon_ionic-ios-arrow-dropleft"
      data-name="Icon ionic-ios-arrow-dropleft"
      transform="translate(-3.375 -3.375)"
    >
      <path
        id="Path_34"
        data-name="Path 34"
        d="M21.185,10.378a1.362,1.362,0,0,0-1.92,0l-6.708,6.729a1.356,1.356,0,0,0-.042,1.87l6.609,6.63a1.355,1.355,0,1,0,1.92-1.912L15.434,18l5.759-5.7A1.356,1.356,0,0,0,21.185,10.378Z"
        fill="#6432ff"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm8.747,23.372A12.37,12.37,0,1,1,9.253,9.253,12.37,12.37,0,1,1,26.747,26.747Z"
        fill="#6432ff"
      />
    </g>
  </svg>
);

export default Subscription;
