import { useRecurly } from "@recurly/react-recurly";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import {
  createSubscription,
  getPrice,
  getRecurlyToken,
  loginUser,
} from "../../services/api/api";
import { generateRandomCode } from "../../utils/utils";
import Button from "../common/Button";
import Loader from "../common/Loader";
// import { loginUser } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import Turnstile from "react-turnstile";
import { routeNames } from "../../router/routes/routeNames";
import { showToast } from "../../utils/toast";
import {
  CardNumberInput,
  CvcInput,
  EmailInput,
  ExpiryInput,
  UserNameInput,
} from "./InputComponents";

const applePayOptions = {
  requiredBillingAddressFields: ["all"],
  requiredShippingContactFields: ["phone", "email", "postalAddress"],
  countryCode: "US",
};

const plan_code = "sevendaytrial-1-main";

const PaymentMethods = ({ methodVisible, setMethodVisible }) => {
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState({
    card: false,
    applePay: false,
  });
  const [appleAvailable, setAppleAvailable] = useState("");
  const [unitPrice, setUnitPrice] = useState(null);
  const [isSupport, setIsSupport] = useState(true);
  const [loading, setLoading] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaSiteKey, setCaptchaSiteKey] = useState("");
  const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState(null);

  const cardMonthRef = useRef();
  const cardYearRef = useRef();
  const cardCvvRef = useRef();
  const navigate = useNavigate();
  const recurly = useRecurly();
  const risk = recurly.Risk();

  const userNameRef = useRef({
    value: "",
    error: t("Enter Username"),
    touched: false,
  });
  const emailRef = useRef({
    value: "",
    error: t("Enter Email"),
    touched: false,
  });
  const cardNumberRef = useRef({
    value: "",
    error: t("Enter Card Number"),
    touched: false,
  });
  const cardMonthValueRef = useRef({
    value: "",
    error: t("Enter Expiry Month"),
    touched: false,
  });
  const cardYearValueRef = useRef({
    value: "",
    error: t("Enter Expiry Year"),
    touched: false,
  });
  const cvcRef = useRef({
    value: "",
    error: t("Enter CVC"),
    touched: false,
  });

  const applePay = recurly.ApplePay({
    country: "US",
    currency: "USD",
    label: "Ask-Me",
    total: `${unitPrice}`,
  });

  // const applePay = null;

  const toggleMethodVisibility = () => {
    setMethodVisible(!methodVisible);
  };

  const divideStringIntoHalf = (nameString) => {
    const length = nameString?.length;
    const middle = Math.floor(length / 2);
    let result = {};

    result.firstName = nameString?.slice(0, middle);
    result.lastName = nameString?.slice(middle);

    return result;
  };

  const fetchPaymentRequirements = async () => {
    const response = await getPrice();

    if (response.status === 200) {
      const priceList = response?.data?.data?.plan_list;
      const currentUnitPrice =
        priceList?.length > 0 &&
        priceList?.find((plans) => plans.plan_code === plan_code);
      setUnitPrice(currentUnitPrice?.unit_amount);
      setIsCaptchaEnabled(response?.data?.data?.captcha_enable);
      setCaptchaSiteKey(response?.data?.data?.captcha_site_key);
      //   setPaypalPlan(currentUnitPrice?.paypal_code);
      //   setCurrentPlan(currentUnitPrice?.plan_code);
    } else {
      setError("Error fetching payment requirements");
    }
    setLoading(false);
  };

  const sendData = async (token) => {
    setLoadingPayment((pre) => ({ ...pre, applePay: true }));
    const code = generateRandomCode();

    try {
      let subscription_payload = {
        email: emailRef?.current?.value?.toLowerCase(),
        token: token.id,
        code: code,
        instagram_username: userNameRef?.current?.value,
        plan_code: plan_code,
        payment_type: "recurly",
      };
      if (isCaptchaEnabled) {
        subscription_payload = {
          ...subscription_payload,
          cf_turnstile_response: captchaToken,
        };
      }
      await createSubscription(subscription_payload)
        .then(async (response) => {
          if (response.status === 200) {
            await loginUser({
              email: emailRef?.current?.value?.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("email", emailRef?.current?.value);
                  showToast(res?.data?.message, "success");
                  navigate(routeNames.otp, {
                    state: { isNavigatedFromPayment: true },
                  });
                  localStorage.setItem(
                    "new-user",
                    JSON.stringify({
                      email: emailRef?.current?.value?.toLowerCase(),
                    })
                  );
                  setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                }
              })
              .catch((err) => {
                showToast(err?.response?.data, "error");
                setLoadingPayment((pre) => ({ ...pre, applePay: false }));
              });
          }
        })
        .catch((error) => {
          showToast(error?.response?.data, "error");
          setLoadingPayment((pre) => ({ ...pre, applePay: false }));
        });
    } catch (e) {
      setLoadingPayment((pre) => ({ ...pre, applePay: false }));
    }
    setPaymentProcessing(false);
    setLoadingPayment((pre) => ({ ...pre, applePay: false }));
  };

  const handleThreeDSPayment = async (payload) => {
    try {
      setLoadingPayment((pre) => ({ ...pre, card: true }));

      const response = await createSubscription(payload);
      if (response.status === 200) {
        await loginUser({
          email: emailRef?.current?.value?.toLowerCase(),
        })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(
                "email",
                emailRef?.current?.value?.toLowerCase()
              );
              showToast(res?.data?.message, "success");
              navigate(routeNames.otp, {
                state: { isNavigatedFromPayment: true },
              });
              localStorage.setItem(
                "new-user",
                JSON.stringify({
                  email: emailRef?.current?.value?.toLowerCase(),
                })
              );
              setLoadingPayment((pre) => ({ ...pre, card: false }));
            }
          })
          .catch((err) => {
            showToast(err?.response?.data, "error");
            setLoadingPayment((pre) => ({ ...pre, card: false }));
          });
      }
    } catch (error) {
      showToast(
        error?.response?.data?.error ??
          error?.response?.data?.message ??
          error?.response?.data ??
          "Something went wrong",
        "error"
      );
      setLoadingPayment((pre) => ({ ...pre, card: false }));
    }
  };

  const handleAppleClick = (event) => {
    event.preventDefault();

    if (!isSupport) {
      showToast(
        "Your device or browser is not compatible with Apple Pay.",
        "error"
      );
      setLoadingPayment((pre) => ({ ...pre, applePay: false }));
      return;
    } else if (
      isSupport &&
      applePay &&
      emailRef?.current?.value &&
      userNameRef?.current?.value
    ) {
      if (!captchaToken && isCaptchaEnabled) {
        showToast("Please Verify you are human", "error");
        setLoadingPayment((pre) => ({ ...pre, applePay: false }));
        return;
      }

      if (emailRef.current?.error || userNameRef.current?.error) {
        userNameRef.current.touched = true;
        emailRef.current.touched = true;
        return;
      }

      applePay?.ready(function () {
        applePay?.begin();
      });

      applePay.on("error", function (err) {
        console.error("Apple Pay error", err);
      });

      applePay.on("token", async function (token) {
        await sendData(token);
      });
    } else {
      if (!emailRef?.current?.value || !userNameRef?.current?.value) {
        showToast("*", "error");
        userNameRef.current.touched = true;
        emailRef.current.touched = true;
      }
    }
  };

  const handleCardPayment = async (event) => {
    event.preventDefault();

    setLoadingPayment((pre) => ({ ...pre, card: true }));

    if (
      cardNumberRef.current.error ||
      cardMonthValueRef.current.error ||
      cardYearValueRef.current.error ||
      cvcRef.current.error ||
      emailRef.current.error ||
      userNameRef.current.error
    ) {
      setIsClicked(true);
      setLoadingPayment((pre) => ({ ...pre, card: false }));
      return;
    }
    if (!captchaToken && isCaptchaEnabled) {
      showToast("Please Verify you are human", "error");
      setLoadingPayment((pre) => ({ ...pre, card: false }));
      return;
    }
    let showErr = false;
    if (!recurly && showErr) {
      setLoadingPayment((pre) => ({ ...pre, card: false }));
      return;
    }
    const { firstName, lastName } = divideStringIntoHalf(
      emailRef.current?.value?.split("@")[0]
    );
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("number", cardNumberRef.current?.value);
    formData.append("month", cardMonthRef.current?.value);
    formData.append("year", cardYearRef.current?.value);
    formData.append("cvv", cvcRef.current?.value);
    formData.append("email", emailRef.current?.value);
    formData.append("three_d_secure_action_required", true);
    formData.append("key", process.env.REACT_APP_RECURLY_KEY);

    await getRecurlyToken(formData)
      .then((response) => {
        if (
          !cardNumberRef.current.error &&
          !cardMonthValueRef.current.error &&
          !cardYearValueRef.current.error &&
          !cvcRef.current.error &&
          !emailRef.current.error &&
          !userNameRef.current.error &&
          !response.message
        ) {
          setLoadingPayment((pre) => ({ ...pre, card: true }));
          const code = generateRandomCode();
          const token = response?.data?.id;
          if (token) {
            try {
              let subscription_payload = {
                email: emailRef.current?.value?.toLowerCase(),
                token: token,
                instagram_username: userNameRef.current?.value,
                code: code,
                plan_code: plan_code,
                payment_type: "recurly",
              };

              if (isCaptchaEnabled) {
                subscription_payload = {
                  ...subscription_payload,
                  cf_turnstile_response: captchaToken,
                };
              }

              createSubscription(subscription_payload)
                .then(async (response) => {
                  if (response.status === 201) {
                    setLoadingPayment((pre) => ({ ...pre, card: true }));
                    const threeDSecure = risk.ThreeDSecure({
                      actionTokenId: response?.data?.action_token_id,
                    });
                    threeDSecure.on("error", (err) => {
                      setLoadingPayment((pre) => ({ ...pre, card: false }));
                      console.log("threeDSecure err", err);
                      showToast("Payment Rejected", "error");
                    });

                    threeDSecure.on("token", (threeDsToken) => {
                      setLoadingPayment((pre) => ({ ...pre, card: true }));
                      let subscription_payload = {
                        email: emailRef?.current?.value?.toLowerCase(),
                        token: token,
                        plan_code: plan_code,
                        three_d_secure_action_result_token_id: threeDsToken?.id,
                        code: code,
                        accountCode: response?.data?.accountCode,
                        accountId: response?.data?.accountId,
                        payment_type: "recurly",
                        instagram_username: userNameRef.current?.value,
                      };

                      if (isCaptchaEnabled) {
                        subscription_payload = {
                          ...subscription_payload,
                          cf_turnstile_response: captchaToken,
                        };
                      }

                      handleThreeDSPayment(subscription_payload);
                    });

                    threeDSecure.attach(document.getElementById("3D-Secure"));
                  }
                  if (response.status === 200) {
                    await loginUser({
                      email: emailRef?.current?.value?.toLowerCase(),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          localStorage.setItem(
                            "email",
                            emailRef?.current?.value
                          );
                          showToast(res?.data?.message, "success");
                          navigate(routeNames.otp, {
                            state: { isNavigatedFromPayment: true },
                          });
                          localStorage.setItem(
                            "new-user",
                            JSON.stringify({
                              email: emailRef?.current?.value?.toLowerCase(),
                            })
                          );
                          setLoadingPayment((pre) => ({ ...pre, card: false }));
                        }
                      })
                      .catch((err) => {
                        showToast(err?.response?.data, "error");
                        setLoadingPayment((pre) => ({ ...pre, card: false }));
                      });
                  }
                })
                .catch((error) => {
                  showToast(
                    error?.response?.data?.error ??
                      error?.response?.data?.message ??
                      error?.response?.data ??
                      "Something went wrong",
                    "error"
                  );
                  setLoadingPayment((pre) => ({ ...pre, card: false }));
                });
            } catch (e) {
              setLoadingPayment((pre) => ({ ...pre, card: false }));
              console.log(e.err);
            }
          }
        } else {
          if (
            response?.message ||
            (!cardNumberRef?.current?.error &&
              !cardYearRef?.current?.error &&
              !cardMonthRef?.current?.error &&
              !cvcRef?.current?.error)
          ) {
            showToast(
              response?.message || "Card number cannot be a test card number",
              "error"
            );
          }
          setLoadingPayment((pre) => ({ ...pre, card: false }));
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    recurly.configure({
      applePay: applePayOptions,
    });
  }, [recurly]);

  useEffect(() => {
    if (!window.ApplePaySession) {
      if (appleAvailable !== "Not Available") {
        setIsSupport(false);
        setAppleAvailable("Not Available");
      }
      return;
    }
  }, [isPaymentProcessing]);

  useEffect(() => {
    fetchPaymentRequirements();
  }, []);

  return (
    <div className="payment-section-v">
      {methodVisible ? (
        loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Loader />
          </div>
        ) : error ? (
          <>
            <p className="fs-14 fw-bold price-error text-center">{error}</p>
          </>
        ) : (
          <div className="payment-methods-container">
            <div className="email-username-input d-flex flex-column">
              <UserNameInput inputRef={userNameRef} isClicked={isClicked} />

              <EmailInput inputRef={emailRef} isClicked={isClicked} />
            </div>
            <div className="card-details d-flex flex-column">
              <CardNumberInput
                inputRef={cardNumberRef}
                cardMonthRef={cardMonthRef}
                isClicked={isClicked}
              />
              <div className="d-flex justify-content-between w-100">
                <div className="expiry-input-wrapper">
                  <ExpiryInput
                    cardMonthRef={cardMonthRef}
                    cardYearRef={cardYearRef}
                    cardMonthValueRef={cardMonthValueRef}
                    cardCvvRef={cardCvvRef}
                    cardYearValueRef={cardYearValueRef}
                    isClicked={isClicked}
                  />
                </div>
                <div className="cvc-input-wrapper">
                  <CvcInput
                    inputRef={cvcRef}
                    cardCvvRef={cardCvvRef}
                    isClicked={isClicked}
                  />
                  <input
                    type="hidden"
                    name="recurly-token"
                    data-recurly="token"
                  />
                </div>
              </div>
            </div>
            {isCaptchaEnabled && (
              <Turnstile
                theme="light"
                className="turnstile-custom"
                fixedSize={true}
                sitekey={captchaSiteKey}
                onVerify={(token) => setCaptchaToken(token)}
              />
            )}
            {loadingPayment?.card ? (
              <div className="w-100 d-flex justify-content-center py-3">
                <Loader />
              </div>
            ) : (
              <Button
                onClick={handleCardPayment}
                // onClick={login}
                label={t("Process Credit Card")}
                hideArrow
              />
            )}
            <p className="or-text text-black text-uppercase text-center fs-14 fw-regular">
              {t("OR")}
            </p>
            {loadingPayment?.applePay ? (
              <div className="w-100 d-flex justify-content-center py-3">
                <Loader />
              </div>
            ) : (
              <button
                className="payment-btn apple-pay-btn w-100"
                onClick={handleAppleClick}
                // onClick={login}
                // onClick={() => showToast("as")}
              >
                <ApplePayIcon />
              </button>
            )}
            <div
              id="3D-Secure"
              className="threeDSecure"
              style={{
                maxWidth: "390px",
              }}
            ></div>
          </div>
        )
      ) : (
        <Button
          label={t("Get My Plan")}
          className={"mb-1"}
          onClick={toggleMethodVisibility}
        />
      )}
    </div>
  );
};

export const ApplePayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58.474"
    height="24"
    viewBox="0 0 58.474 24"
  >
    <path
      id="Icon_awesome-apple-pay"
      data-name="Icon awesome-apple-pay"
      d="M10.681,11.856A3.587,3.587,0,0,1,7.8,13.217a4.05,4.05,0,0,1,1.032-2.978,4.3,4.3,0,0,1,2.86-1.471,4.347,4.347,0,0,1-1.014,3.088m1,1.571c-1.59-.091-2.951.9-3.7.9-.767,0-1.919-.859-3.179-.831A4.7,4.7,0,0,0,.814,15.921C-.9,18.872.367,23.23,2.029,25.633c.813,1.188,1.781,2.494,3.06,2.448C6.3,28.035,6.78,27.3,8.242,27.3s1.9.786,3.179.767c1.325-.027,2.156-1.188,2.969-2.375a10.327,10.327,0,0,0,1.325-2.732,4.316,4.316,0,0,1-2.585-3.919,4.395,4.395,0,0,1,2.092-3.682,4.515,4.515,0,0,0-3.545-1.928m9.172-3.307V27.926h2.768V21.841h3.828a5.65,5.65,0,0,0,5.947-5.874,5.585,5.585,0,0,0-5.856-5.847H20.849Zm2.768,2.33h3.188c2.4,0,3.773,1.279,3.773,3.526s-1.37,3.545-3.782,3.545H23.617ZM38.436,28.063a4.561,4.561,0,0,0,4.075-2.275h.055v2.138h2.558V19.064c0-2.567-2.056-4.23-5.217-4.23-2.933,0-5.107,1.681-5.189,3.983h2.494A2.432,2.432,0,0,1,39.824,17c1.69,0,2.64.786,2.64,2.238v.987l-3.453.21c-3.207.192-4.942,1.507-4.942,3.791C34.078,26.528,35.868,28.063,38.436,28.063Zm.749-2.11c-1.471,0-2.412-.713-2.412-1.791,0-1.124.9-1.772,2.631-1.873l3.07-.192v1a3,3,0,0,1-3.289,2.85Zm9.364,6.815c2.7,0,3.965-1.032,5.07-4.148L58.47,15.008H55.657L52.4,25.523h-.055L49.1,15.008H46.21l4.678,12.964-.256.786a2.2,2.2,0,0,1-2.33,1.855c-.219,0-.64-.027-.813-.046V32.7A9.576,9.576,0,0,0,48.549,32.768Z"
      transform="translate(0.004 -8.768)"
      fill="#fff"
    />
  </svg>
);

export default PaymentMethods;
