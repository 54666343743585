import { addS3Url } from "../../utils/utils";
// import testimonial1_es from "../17-11/videos/Testimonial_1_es.mp4";
// import testimonial2_es from "../17-11/videos/Testimonial_2_es.mp4";
// import testimonial3_es from "../17-11/videos/Testimonial_3_es.mp4";
// import testimonial4_es from "../17-11/videos/Testimonial_4_es.mp4";

// import featured1 from "../17-11/payment/featured-1-es.png";
// import featured2 from "../17-11/payment/featured-2-es.png";
// import featured3 from "../17-11/payment/featured-3-es.png";
// import featured4 from "../17-11/payment/featured-4-es.png";
// import featuredMask1 from "../17-11/payment/featured-mask-1-es.png";
// import featuredMask2 from "../17-11/payment/featured-mask-2-es.png";
// import featuredMask3 from "../17-11/payment/featured-mask-3-es.png";
// import featuredMask4 from "../17-11/payment/featured-mask-4-es.png";

export const aws_images = {
  growingUser: addS3Url("/payment/grow-user.png"),
  featuredMask1: addS3Url("/payment/featured-mask-1.png"),
  featured1: addS3Url("/payment/featured-1.png"),
  featuredMask2: addS3Url("/payment/featured-mask-2.png"),
  featured2: addS3Url("/payment/featured-2.png"),
  featuredMask3: addS3Url("/payment/featured-mask-3.png"),
  featured3: addS3Url("/payment/featured-3.png"),
  featuredMask4: addS3Url("/payment/featured-mask-4.png"),
  featured4: addS3Url("/payment/featured-4.png"),
  featuredMask5: addS3Url("/payment/featured-mask-5.png"),
  featured5: addS3Url("/payment/featured-5.png"),

  featuredMask1_es: addS3Url("/payment/featured-mask-1-es.png"),
  featured1_es: addS3Url("/payment/featured-1-es.png"),
  featuredMask2_es: addS3Url("/payment/featured-mask-2-es.png"),
  featured2_es: addS3Url("/payment/featured-2-es.png"),
  featuredMask3_es: addS3Url("/payment/featured-mask-3-es.png"),
  featured3_es: addS3Url("/payment/featured-3-es.png"),
  featuredMask4_es: addS3Url("/payment/featured-mask-4-es.png"),
  featured4_es: addS3Url("/payment/featured-4-es.png"),

  // featuredMask1_es: featuredMask1,
  // featured1_es: featured1,
  // featuredMask2_es: featuredMask2,
  // featured2_es: featured2,
  // featuredMask3_es: featuredMask3,
  // featured3_es: featured3,
  // featuredMask4_es: featuredMask4,
  // featured4_es: featured4,
  // featuredMask1: FeatureMask1,
  // featured1: Feature1,
  // featuredMask2: FeatureMask2,
  // featured2: Feature2,
  // featuredMask3: FeatureMask3,
  // featured3: Feature3,
  // featuredMask4: FeatureMask4,
  // featured4: Feature4,
  // featuredMask5: FeatureMask5,
  // featured5: Feature5,
  activity_profile: addS3Url("/activity/profile.png"),
};

export const aws_videos = {
  // testimonial1: testimonial1,
  // testimonial2: testimonial2,
  // testimonial3: testimonial3,
  // testimonial4: testimonial4,
  // testimonial5: testimonial5,
  testimonial1: addS3Url("/videos/Testimonial_1.mp4"),
  testimonial2: addS3Url("/videos/Testimonial_2.mp4"),
  testimonial3: addS3Url("/videos/Testimonial_3.mp4"),
  testimonial4: addS3Url("/videos/Testimonial_4.mp4"),
  testimonial5: addS3Url("/videos/Testimonial_5.mp4"),
  testimonial1_es: addS3Url("/videos/Testimonial_1_es.mp4"),
  testimonial2_es: addS3Url("/videos/Testimonial_2_es.mp4"),
  testimonial3_es: addS3Url("/videos/Testimonial_3_es.mp4"),
  testimonial4_es: addS3Url("/videos/Testimonial_4_es.mp4"),
  // testimonial1_es: testimonial1_es,
  // testimonial2_es: testimonial2_es,
  // testimonial3_es: testimonial3_es,
  // testimonial4_es: testimonial4_es,
};
