import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en } from "./resources/en";
import { es } from "./resources/es";
import { legal_en_Translation } from "./resources/legal/en";
import { legal_es_Translation } from "./resources/legal/es";

const supportedLngs = ["en", "es"];

const resources = {
  en: {
    translation: {
      ...legal_en_Translation,
      ...en,
    },
  },
  es: {
    translation: {
      ...legal_es_Translation,
      ...es,
    },
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === "development",
    supportedLngs: supportedLngs,
    detection: {
      order: ["navigator"],
      convertDetectedLanguage: (props) => {
        const lang = props.split("-")[0];
        const isSupported = supportedLngs.includes(lang);
        // const isSupported = ["en", "es"].includes(lang);
        return isSupported ? lang : "en";
      },
    },
    resources,
  });

export default i18next;
