import Activity from "../../pages/activity/Activity";
import Forgot from "../../pages/auth/forgot/Forgot";
import PrivacyPolicy from "../../pages/auth/legal/PrivacyPolicy";
import Terms from "../../pages/auth/legal/Terms";
import Login from "../../pages/auth/login/Login";
import Otp from "../../pages/auth/otp/Otp";
import Payment from "../../pages/auth/payment/Payment";
import Subscription from "../../pages/subscription/Subscription";
import { routeNames } from "./routeNames";

export const publicRoutes = [
  {
    path: routeNames.payment,
    page: Payment,
  },
  {
    path: routeNames.paymentP1,
    page: Payment,
  },
  {
    path: routeNames.paymentP2,
    page: Payment,
  },
  {
    path: routeNames.otp,
    page: Otp,
  },
  {
    path: routeNames.login,
    page: Login,
  },
  {
    path: routeNames.forgot,
    page: Forgot,
  },
];

export const commonRoutes = [
  {
    path: routeNames.privacy,
    page: PrivacyPolicy,
  },
  {
    path: routeNames.terms,
    page: Terms,
  },
];

export const protectedRoutes = [
  {
    path: routeNames.home,
    page: Activity,
  },
  {
    path: routeNames.subscription,
    page: Subscription,
  },
];
